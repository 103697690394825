import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Text } from "@arwes/react-text";
import { Underline } from "../arwes/Buttons/UnderlineButton";
import "../utility/neon.css";

const NavMenu = ({setNavOpen, navOpen}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center gap-3 w-full ">
        
       <Underline
        onClick={() => {setNavOpen(false);}}
        className="2xl:w-[70%] xl:w-[60%] lg:w-[45%] w-[60%]"
      >
        <Link to="https://twelvezodiac.co">
        <Text
          style={{ color: "white" }}
          manager="decipher"
          easing="outSine"
          fixed
          className='font-["Play"] font-bold 2xl:text-base xl:text-base lg:text-base text-xs'
        >
          Home
        </Text>
        </Link>
      </Underline>
      
      <Underline
        onClick={() => {navigate("/", { replace: true });
        setNavOpen(false);}}
        className="2xl:w-[70%] xl:w-[60%] lg:w-[45%] w-[60%] "
      >
        <Text
          style={{ color: "white" }}
          manager="decipher"
          easing="outSine"
          fixed
          className='font-["Play"] font-bold 2xl:text-base xl:text-base lg:text-base text-xs '
        >
          Dashboard
        </Text>
      </Underline>
      <Underline
        onClick={() => {navigate("/marketplace", { replace: true });
        setNavOpen(false);}}
        className="2xl:w-[70%] xl:w-[60%] lg:w-[45%] w-[60%] "
      >
        <Text
          style={{ color: "white" }}
          manager="decipher"
          easing="outSine"
          fixed
          className='font-["Play"] font-bold 2xl:text-base xl:text-base lg:text-base text-xs '
        >
          Summon Zodiac
        </Text>
      </Underline>
      <Underline
        onClick={() => {navigate("/collection", { replace: true });
        setNavOpen(false);}}
        className="2xl:w-[70%] xl:w-[60%] lg:w-[45%] w-[60%] "
      >
        <Text
          style={{ color: "white" }}
          manager="decipher"
          easing="outSine"
          fixed
          className='font-["Play"] font-bold 2xl:text-base xl:text-base lg:text-base text-xs '
        >
          Zodiac
        </Text>
      </Underline>
      <Underline
        onClick={() =>{ 
          navigate("/earning", { replace: true });
      setNavOpen(false);}}
        className="2xl:w-[70%] xl:w-[60%] lg:w-[45%] w-[60%] "
      >
        <Text
          style={{ color: "white" }}
          manager="decipher"
          easing="outSine"
          fixed
          className='font-["Play"] font-bold 2xl:text-base xl:text-base lg:text-base text-xs '
        >
          Earning
        </Text>
      </Underline>

    </div>
  );
};

export default NavMenu;
