/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRef } from "react";
import {
  FrameSVGOctagon,
  useFrameSVGAssemblingAnimation,
} from "@arwes/react-frames";

export const FrameOctagon = () => {
  const svgOcta = useRef(null);
  const { onRender } = useFrameSVGAssemblingAnimation(svgOcta);

  return (
    <div>
      <FrameSVGOctagon
        elementRef={svgOcta}
        onRender={onRender}
        padding={4}
        css={css`
     

      [data-name="bg"] {
        color: rgba(3, 121, 172, 0.59);
        filter: 'drop-shadow(0 0 4px hsl(60, 75%, 10%))';
      },
      [data-name="line"] {
        color: rgba(0, 240, 253, 0.8);
        filter: 'drop-shadow(0 0 4px hsl(60, 75%, 50%))';
      }`}
      />
    </div>
  );
};
