import React from 'react'
import './marketplace.css';
import { SummoningCircle } from './pageComponents/marketPageComponents/summoningCircle';


const FixedLayer = () => {
    return (
      <div className="fixed-layer-marketplace">
       
      </div>
    );
  };

const Marketplace = ({account}) => {
  return (
    <div className="marketplace">
      <FixedLayer />

      <div className="scrollable-layer z-10">
      
       <div className="wrapper absolute z-20 w-full h-full">
        <section className="section h-screen w-screen flex justify-center items-center">
         
          <SummoningCircle account={account}/>
        </section>
       
        </div>
      </div>
    </div>
  )
}

export default Marketplace;