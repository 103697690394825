import React from "react";
import "./App.css";
import Routers from "./routes/routers";
import Navigation from "./components/navigation/navigation";
import {useAccount} from "wagmi";

function App() {
  const { address} = useAccount();
  // const address = '0xD1A2478CfC6b2eE0063CC8BfdadB3C1eccBf2C2e';

  return (
    <>
      {/* <ScrollLock /> */}
      <div className="App">
        <Navigation />
        <Routers account={address} />
      </div>
    </>
  );
}

export default App;
