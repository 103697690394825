import React from 'react'
import { FrameCorners } from "../../../components/arwes/frames/frameCorners";
import numeral from "numeral";
import { formatUnits } from 'viem';
import fromExponential from "from-exponential";
export const StakeItemsInfo = ({amount, planId, earned}) => {

  return (
    

    <>
    <div className="stakeAmount p-3 bg-sky-700/80 relative">
                  <FrameCorners />
                  <div className="text relative">
                    <p className="text-xs text-start font-bold uppercase">
                      Amount
                    </p>
                    <p className="text-base text-start font-bold font-play text-[#4FD1C5]">
                    {amount !== undefined
                      ? numeral(
                        parseFloat(formatUnits(fromExponential(amount), 18))
                        ).format("0,0.00")
                      : "0.00"}
                    </p>
                  </div>
                </div>
                <div className="stakePlan p-3 bg-sky-700/80 relative">
                  <FrameCorners />
                  <div className="text relative">
                    <p id={planId !== undefined ? planId : "0"} className="text-xs text-center font-bold uppercase">
                      Selected Plan
                    </p>
                    <p className="text-base text-text-center font-bold font-play text-[#4FD1C5]">
                    {planId === 0 && 'None'}
                    {planId === 1 && 'Flexible'}
                    {planId === 2 && '3 Month'}
                    {planId === 3 && '6 Month'}
                    </p>
                  </div>
                </div>
                <div className="stakeEarned p-3 bg-sky-700/80 relative">
                  <FrameCorners />
                  <div className="text relative">
                    <p className="text-xs text-end font-bold uppercase">
                      Earned
                    </p>
                    <p className="text-base text-end font-bold font-play text-[#4FD1C5]">
                    {earned !== undefined
                      ? numeral(
                          parseFloat(formatUnits(fromExponential(earned), 18))
                        ).format("0,0.00")
                      : "0.00"}
                    </p>
                  </div>
                </div>
    </>
  )
}
