import React from "react";
// import Zodiac12 from "../../../asset/image/Zodiac12snow.png";
import Dragon from "../../../asset/image/Zodiac12.webp";
// import Flame from "../../../asset/image/flame 1.png";
import { FrameOctagon } from "../../../components/arwes/frames/frameOctagon";
import numeral from "numeral";
import "./blink.css";

const GeneralInfo = ({ burn, staking, burned, circular }) => {
  return (
    <div className="GeneralInfo w-full flex my-10 justify-center items-center ">
      <div className="wrapper 2xl:w-[80%] xl:w-[80%] lg:w-[80%] w-[90%] flex 2xl:flex-row xl:flex-row flex-col">
        <div className="left 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full px-3 flex justify-center">
          <img
            src={Dragon}
            alt=""
            className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full object-contain"
          />
        </div>
        <div className="right 2xl:w-1/2 xl:w-1/2 lg:w-1/2 w-full">
          <div className="generalInfoTitle relative p-4">
            <FrameOctagon />
            <div className="about-content relative w-full flex flex-col">
              <p className="text-center">General Info</p>
              <div className="content my-3 bg-[#00ffff]/10 p-5">
                <div className="content grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 w-full gap-5 ">
                  <div className="circularSupplyInfo place-self-center bg-[#00FFFF] border border-[#00FFFF] w-full">
                    <div
                      className="boxInfo  place-self-center bg-black/95 p-2 border border-[#00FFFF] w-full"
                      style={{
                        clipPath: `polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%)`,
                      }}
                    >
                      <p className="text-[#00ffff] text-sm font-semibold inline-flex items-center">
                        Circular Supply{" "}
                        <span className="blinkDot ml-2 text-2xl animate-ping">
                          |
                        </span>{" "}
                      </p>
                      <div className="box h-20 w-full border border-[#00ffff] bg-[#00ffff]/10 mt-4 flex justify-center items-center">
                        <p className="font-bold">
                          {circular !== undefined
                            ? numeral(circular).format("0,0.0000")
                            : "0.0000"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="StakingInfo place-self-center bg-[#00FFFF] border border-[#00FFFF] w-full">
                    <div
                      className="boxInfo  place-self-center bg-black/95 p-2 border border-[#00FFFF] w-full"
                      style={{
                        clipPath: `polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%)`,
                      }}
                    >
                      <p className="text-[#00ffff] text-sm font-semibold inline-flex items-center">
                        Total Earning{" "}
                        <span className="blinkDot ml-2 text-2xl animate-ping">
                          |
                        </span>
                      </p>
                      <div className="box h-20 w-full border border-[#00ffff] bg-[#00ffff]/10 mt-4 flex justify-center items-center">
                        <p className="font-bold">
                          {staking !== undefined
                            ? numeral(staking).format("0,0.0000")
                            : "0.0000"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="toBurnInfo place-self-center bg-[#00FFFF] border border-[#00FFFF] w-full">
                    <div
                      className="boxInfo  place-self-center bg-black/95 p-2 border border-[#00FFFF] w-full"
                      style={{
                        clipPath: `polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%)`,
                      }}
                    >
                      <p className="text-yello text-sm font-semibold inline-flex items-center">
                        Ready to Burn{" "}
                        <span className="blinkDot ml-2 text-2xl animate-ping">
                          |
                        </span>
                      </p>
                      <div className="box h-20 w-full border border-[#00ffff] bg-yello/80 animate-pulse mt-4 flex justify-center items-center">
                        <p className="font-bold">
                          {burn !== undefined
                            ? numeral(burn).format("0,0.0000")
                            : "0.0000"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="burnInfo place-self-center bg-[#00FFFF] border border-[#00FFFF] w-full">
                    <div
                      className="boxInfo  place-self-center bg-black/95 p-2 border border-[#00FFFF] w-full"
                      style={{
                        clipPath: `polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%)`,
                      }}
                    >
                      <p className="text-red-600 text-sm font-semibold inline-flex items-center">
                        Burn{" "}
                        <span className="blinkDot ml-2 text-2xl animate-ping">
                          |
                        </span>
                      </p>
                      <div className="box h-20 w-full border border-[#00ffff] bg-red-600 animate-pulse mt-4 flex justify-center items-center">
                        <p className="font-bold">
                          {burned !== undefined
                            ? numeral(burned).format("0,0.0000")
                            : "0.0000"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
