import React from 'react'
import { FrameUnderlines } from "../../../components/arwes/frames/frameUnderline";

export const StakeItemsNFTTitle = ({title, tokenid}) => {
  return (
    <>
    <div className="NFTName  flex flex-col justify-center">
                    <p className="text-xs text-start font-bold uppercase">
                      Zodiac
                    </p>
                    <p className="2xl:text-2xl xl:text-2xl lg:text-2xl text-lg text-start font-bold font-play text-[#4FD1C5]">
                      {title}
                    </p>
                  </div>
                  {/* <div className="divide bg-white" /> */}
                  <div className="NFTtokenId p-3 2xl:w-[25%] xl:w-[25%] lg:w-[25%] w-[20%] place-self-end relative">
                    <FrameUnderlines />
                    <div className="text relative">
                      <p className="text-xs text-end font-bold uppercase">
                        TOKEN ID
                      </p>
                      <p className="2xl:text-2xl xl:text-2xl lg:text-2xl text-xl text-end font-bold font-play text-[#4FD1C5]">
                       {tokenid}
                      </p>
                    </div>
                  </div>
    </>
  )
}
