import React from 'react';
import { Animator } from '@arwes/react';
import { FrameUnderlines } from '../frames/frameUnderline';

export function Underline ({
    type = 'button',
    onClick,
    as: Component = 'button',
    children,
    ...rest
  })  {

    const buttonStyles = {
      position: 'relative',
      paddingTop: '7px',
      paddingBottom: '7px',
      paddingLeft: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      ...rest.style, // Allow additional styles to be passed via props
    };

  return (
    <Component
          onClick={onClick}
        style={buttonStyles}
        {...rest}
        >
          <FrameUnderlines />
        <Animator>
          {children}
          </Animator>
        </Component>
  )
}

export default Underline;