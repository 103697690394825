/* global BigInt */
import React from "react";
import { FrameLines } from "../../../components/arwes/frames/frameLines";
import { useFetchBalance } from "../../../components/hooks/useFetchBalance";

export const StakeItemsFormInput = ({max, min, setStakesAmount, stakesAmount, allowance, setNeedApprove, amount, setAmount}) => {
  const { token } = useFetchBalance();

  const changeAmount = (e) => {
    const inputValue = e.target.value;
    const numericValue = parseFloat(inputValue); // Parse as a regular number

    if (!isNaN(numericValue)) {
      setAmount(inputValue); // Update the input value in the state
      const reqAmount = BigInt(numericValue * 10 ** 18); // Convert to BigInt
      setStakesAmount(reqAmount);
      setNeedApprove(allowance < reqAmount); // Simplified the approval check
    } else {
      setAmount(inputValue); // Set the input value in the state, even if it's invalid
      setNeedApprove(true);
    }
  };

  const handleMax = () => {
    if (token.formatted >= max) {
      setAmount(max);
    } else if (token.formatted <= max && token.formatted > min) {
      setAmount(token.formatted);
    }
    
    const amountFormat = BigInt(amount * 10 ** 18); // Convert max to BigInt
    setStakesAmount(amountFormat);
    setNeedApprove(allowance < amountFormat);
  }



  return (
    <div className="inputAmount relative">
      <FrameLines />
      <div className="inputs relative w-full flex justify-between items-center">
        <div className="forms py-4 px-3 w-[70%] bg-sky-700/80 flex justify-between items-center">
        <input
          type="number"
          className="w-full px-2 bg-transparent text-white 2xl:text-2xl xl:text-2xl lg:text-2xl md:text-2xl text-xl font-black border-none outline-none"
          value={amount}
          min={min}
          max={max}
          onChange={changeAmount}
          placeholder="0"
        />
        
        <button onClick={handleMax} className="px-4 py-1 rounded-lg bg-[#4FD1C5] text-[#1c4945] focus:text-white active:text-white font-semiibold">MAX</button>
        </div>
        <p className="text-[#4FD1C5] font-semibold px-3 py-3 font-play">
          $TWELVE
        </p>
        
      </div>
      <span className="text-white text-xs font-semibold p-2 font-roboto relative">
        Min : {min} $TWELVE
      </span>
      <span className="text-white text-xs font-semibold p-2 font-roboto relative">
        Max : {max} $TWELVE
      </span>
    </div>
  );
};
