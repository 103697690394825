import React from 'react'
import { FaLink } from "react-icons/fa";

export const ConnectedDesktop = ({onClick}) => {
  return (
    <div className="connected font-['Play']">
                <button  onClick={onClick} className="h-[50px] px-9 border-2 border-blue12 border-t-0 border-r-0 rounded-bl-3xl rounded-tr-3xl slide-box-light slide_right flex justify-center items-center text-lg text-[#4FD1C5] hover:text-white">
                 <span className="inline-flex items-center gap-3"> <FaLink /> Connected </span>
                </button>
              </div> 
  )
}
