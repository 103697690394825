import React from "react";
import cny from "../../asset/image/ied01.jpg";

const BtnGift = ({ onClick, isConnected }) => {
  return (
    <button
      onClick={onClick}
      className="animate-fadeIn 2xl:w-1/3 xl:w-1/3 lg:w-1/3 w-full  flex justify-center items-center relative rounded-3xl"
    >
      <img src={cny} alt="" className="2xl:w-[85%] xl:w-[85%] lg:w-[85%] w-[90%] h-auto bg-cover drop-shadow-[0_0_3px_rgba(0,0,0,1)]" />
      <div
        className={`title absolute p-20 bottom-0 ${
          isConnected ? "opacity-100 duration-500 transition-all" : "opacity-0 duration-500 transition-all"
        }`}
      >
        <div className="info w-full h-full flex flex-col gap-5 justify-center items-center px-3 py-2 rounded-xl bg-green-800/70">
          <p className="text-2xl font-bold text-yellow-200 drop-shadow-[0_0_3px_rgba(0,0,0,1)] px-9">
            To Celebrate Eid Mubarak 2024, we want to give FREE $Twelve token as
            giveaway for member who stake $Twelve
          </p>
          <p className="px-3 py-2 bg-green-950 rounded-xl hover:text-white hover:bg-green-600">Click here to get Your Reward</p>
        </div>
      </div>
    </button>
  );
};

export default BtnGift;
