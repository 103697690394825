import React, { useEffect, useState, useCallback, useRef } from "react";
import { Text } from "@arwes/react";
import { Animator } from "@arwes/react-animator";
import { UDisplay as Preloader } from "../../../components/utility/preloader";
import { useAccount } from "wagmi";
import axios from "axios";
import { REACT_APP_API_ENDPOINT } from "../../../config";
import GeneralInfo from "./generalInfo";
// import { formatUnits } from "viem";
// import numeral from "numeral";

import BtnGift from "../../../components/buttons/BtnGift";
import { getAccount } from "wagmi/actions";
import { ModalGiveaway } from "../../../components/modal/modalGiveaway";
// import NewYear from "../../../asset/video/2024.mp4"



const StartLoad = () => {
  const [active, setActive] = useState(true);
  const [showModal, setShowModal] = useState(false);
  // const [isProgress, setIsProgress] = useState(false);
  const [claimed, setClaimed] = useState();
  const [eligible, setEligible] = useState();
  const [error, setError] = useState();
  const [info, setInfo] = useState({
    toBurn: 0,
    totalStaking: 0,
    burned: 0,
    circular: 0,
  });





  const account = getAccount()
  // const account = { address: "0xa8040d9bddb4bee37dab248eaa9560f1f7ee861e"}
  const { isConnected } = useAccount();

  const getInfo = useCallback(async () => {
    try {
      const response = await axios.get(
        REACT_APP_API_ENDPOINT + "/informations"
      );
      const data = response.data?.data;

      setInfo({
        toBurn: data?.ready_to_burn,
        totalStaking: data?.total_staking,
        burned: data?.token_burned,
        circular: data?.circular_supply,
      });
    } catch (err) {
      setError(err);
      console.log(error)
    }
  }, [error]); 

  useEffect(() => {
    getInfo();
  }, []); 

  const handleCheckReward = useCallback(async() => {

    try {
      const response = await axios.get(
        REACT_APP_API_ENDPOINT + "/events/ramadhan2024/checkaddress?address=" + account.address,
       
      );
      const data = response.data;
      setEligible(data.eligible);
  
    } catch (error) {
      setError(error);
      console.log('Cannot fetch data :', error)
 
    }
    
  }, [account.address]);

  useEffect(() => {
    if (isConnected) {
      handleCheckReward();
    }
  }, [handleCheckReward, isConnected]);


  return (
    <div className="containerLoader h-screen w-screen flex flex-col justify-center items-center relative overflow-hidden">
      <div className="wrapperLoader w-full relative flex justify-center items-center bg-blue-800">
        {isConnected ? (
          <Animator active={active} duration={{ enter: 5, exit: 2 }}>
            <Text
              manager="decipher"
              easing="outSine"
              className="text-3xl font-prompt font-semibold text-[#4FD1C5] absolute z-10 w-[300px]"
            >
              CONNECTED
            </Text>
          </Animator>
        ) : (
          <>
            <Preloader />
            <Animator active={active} duration={{ enter: 1, exit: 1 }}>
              <Text
                manager="decipher"
                easing="outSine"
                className="text-3xl font-prompt font-semibold text-[#4FD1C5] absolute z-10 w-[300px]"
              >
                L O A D I N G
              </Text>
            </Animator>
            
            {/* <video
              ref={videoRef}
              autoPlay={false}
              loop="loop"
              muted="muted"
              src={NewYear}
              className={`NFT aspect-video ${loading ? "" : "hidden"}`} // Hide the video when loading is true
              onLoadedData={handleVideoLoad}
            >
              <source src={NewYear} />
            </video> */}
  
          </>
        )}
         
      </div>
      {isConnected && <GeneralInfo burn={info.toBurn} burned={info.burned} staking={info.totalStaking} circular={info.circular} />}
      <div className="absolute top-0 right-0  w-full h-full flex justify-center items-center z-[100]">
      {isConnected && eligible && <BtnGift isConnected={isConnected} onClick={() => setShowModal(true)} />}
      {showModal && <ModalGiveaway show={showModal} onClose={() => setShowModal(false)} account={account.address} claimed={claimed} setClaimed={setClaimed} />}
      </div>
    </div>
  );
};

export default StartLoad;
