import React from 'react'
import { Sling as Hamburger } from "hamburger-react";

export const HamburgerMobile = ({navOpen, setNavOpen}) => {
  return (
    <button
                  // onClick={() => setNavOpen(!navOpen)}
                  className="burger flex items-center"
                >
                  <div className="buttonHam h-[50px] rounded-tr-3xl button_slide slide_right border-2 border-t-0 border-r-0 border-blue12 ">
                    <Hamburger
                      toggled={navOpen}
                      toggle={setNavOpen}
                      size={24}
                      duration={0.3}
                      easing="ease-in"
                      color="#4FD1C5"
                    />
                  </div>
                </button>
  )
}
