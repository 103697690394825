import React from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { useSwipeable } from "react-swipeable";
import { NFTSrc } from "../../../components/utility/mapZodiac";
import "../../../components/utility/neon.css"
const SummonZodiac = ({
  defaultChar,
  handlePrevChar,
  handleNextChar,
  charOpacity,
}) => {
  const charHandlers = useSwipeable({
    onSwipedLeft: handleNextChar,
    onSwipedRight: handlePrevChar,
  });

  return (
    <div className="zodiac 2xl:mt-16 xl:mt-16 lg:mt-16 mt-5 flex flex-col justify-center items-center">
      <p className="text-white text-3xl">Select Zodiac</p>
      <p className="text-center text-xl font-bold font-['Play']">
        {NFTSrc[defaultChar - 1].title}
      </p>
      <div className="char flex" {...charHandlers}>
        <button onClick={handlePrevChar} className="arrow-button mx-2">
          <RiArrowLeftSLine className="arrow-icon 2xl:text-6xl xl:text-4xl text-3xl font-bold" />
        </button>

        <div
           className={`image-container ${
            charOpacity === 1 ? "opacity-100" : "opacity-0"
          } transition-opacity duration-300`}
        >
          <img
            src={NFTSrc[defaultChar - 1].image}
            alt=""
            className="race 2xl:h-[350px] xl:h-[300px] h-[250px] "
          />
        </div>

        <button onClick={handleNextChar} className="arrow-button mx-2">
          <RiArrowRightSLine className="arrow-icon 2xl:text-6xl xl:text-4xl text-3xl font-bold" />
        </button>
      </div>
    </div>
  );
};

export default SummonZodiac;
