/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef } from 'react';
import { FrameSVGKranox, useFrameSVGAssemblingAnimation } from '@arwes/react-frames';

export const FrameNox = () => {
  const svgNox = useRef(null);
  const { onRender } = useFrameSVGAssemblingAnimation(svgNox);

  return (
    <div className='w-full '>
      <FrameSVGKranox
        elementRef={svgNox}
        onRender={onRender}
        padding={14}
        strokeWidth={2}
        squareSize={10}
        smallLineLength={100}
        largeLineLength={55}
        css={css`
      [data-name="bg"] {
        color: rgba(3, 121, 172, 0.59);
        filter: 'drop-shadow(0 0 4px hsl(60, 75%, 10%))';
        backdrop-filter: blur(10px);
 
      },
      [data-name="line"] {
        color:  #4FD1C5;
        filter: 'drop-shadow(0 0 4px hsl(60, 75%, 50%))';
      `}
      />
       
    </div>
  );
};

