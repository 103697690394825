/* global BigInt */
import { useState, useEffect } from 'react';
import { useBlockNumber } from 'wagmi';

export const useGetBlockNumber = (planId, interval = 15000) => {
  const { data, isFetched, isError } = useBlockNumber({
    enabled:false,

  });

  const [currentBlock, setCurrentBlock] = useState(null);

  useEffect(() => {
    const fetchBlockNumber = () => {
      if (isFetched && !isError && data !== undefined) {
        const blockNum = BigInt(data).toString();
        setCurrentBlock(parseFloat(blockNum));
      }
    };

    fetchBlockNumber(); // Call immediately

    const intervalId = setInterval(fetchBlockNumber, interval);

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, [data, isFetched, isError, interval]);

  // Return the current block number only if the planId matches 2 or 3
  return (planId === 2 || planId === 3) ? currentBlock : null;
};
