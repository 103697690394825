import React from 'react'
import { FrameLines } from '../../../components/arwes/frames/frameLines'

export const TitleCollection = () => {
  return (
    <div className="title-container w-full flex justify-end items-center relative mt-20 mb-10">
          <FrameLines/>
          <div className="title text-3xl my-5 mr-4 relative">Zodiac Collection
          </div>
          
          </div>
  )
}
