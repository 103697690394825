import { useEffect } from "react";
import Giveaway from "../gifts/Giveaway";
import "./modal.css";

export function ModalGiveaway({ show, onClose, account, claimed, setClaimed}) {

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.id === "giveaway") {
        onClose();
      }
    };

    if (show) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [show, onClose]);


  if (!show) return null;

  const modalClassName = `containerModal fixed inset-0 pointer-events-auto  bg-black/60 backdrop-blur-md flex justify-center items-center transition-all ${
    show ? "fade-in" : "fade-out"
  }`;

  return (
    <div id="giveaway" onClick={onClose} className={modalClassName}>
      
      <div className="giveaway flex justify-center items-center 2xl:w-1/3 xl:w-1/3 lg:w-1/3 w-full h-full 2xl:px-0 xl:px-0 lg:px-0 px-5"
      onClick={(e) => e.stopPropagation()}>
      <Giveaway account={account} claimed={claimed} setClaimed={setClaimed}/>
      </div>
        
      
    </div>
  );
}
