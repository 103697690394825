import React, { useState } from "react";
import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import Swal from "sweetalert2";
import { TOKENADDR, STAKEADDR, TOKENABI } from "../../../config";
import { OctagonButton } from "../../arwes/Buttons/OctagonButton";
import { formatUnits } from "viem";

export function ApproveStaking({ stakesAmount, setNeedApprove }) {
  const [error, setError] = useState();
  const [isProgress, setIsProgress] = useState(false);

  const handleApproveStake = async () => {
    if (isProgress) return;
    const formatStakes = formatUnits(stakesAmount, 18);
    if (stakesAmount === 0) {
      Swal.fire({
        icon: "error",
        title: "No Value",
        text: "There's no value to be Approved",
      });

      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: ` You are going to use ${formatStakes} Twelve`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, approve it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      reverseButtons: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // User clicked "Approve"
        setIsProgress(true);
        try {
          //   const formattedAmount = parseUnits(amount, 18);
          const { request } = await prepareWriteContract({
            address: TOKENADDR,
            abi: TOKENABI,
            functionName: "approve",
            args: [STAKEADDR, stakesAmount],
          });
          Swal.fire({
            title: "Please Wait",
            html: "Connecting to <strong classNames='text-blue-900'>Network...</strong>",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          const { hash } = await writeContract(request);
          Swal.fire({
            title: "Please Wait",
            html: "Writing Transaction...",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          const data = await waitForTransaction({ hash });

          if (data) {
            const bscScanLink = `https://bscscan.com/tx/${hash}`;
            Swal.fire({
              icon: "success",
              title: "Horray..",
              html: `Transaction success! <a href=${bscScanLink} target="_blank" rel="noopener noreferrer"><u><b>View Here</b> </u></a>`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: "Transaction failed or was canceled",
            });
            setIsProgress(false);
          }
        } catch (err) {
          setError(err);
          console.log(error);
          if (error) {
            if (error.details || error.shortMessage) {
              Swal.fire({
                icon: "error",
                title: "Transaction Cancelled",
                html: `${error.details || error.shortMessage}`,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error on Approval Function",
                html: "Please contact support@twelvezodiac.co",
              });
            }
          }

          setIsProgress(false);
          setNeedApprove(true);
        } finally {
          setIsProgress(false);
          setNeedApprove(false);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked "Cancel"
        Swal.fire("Cancelled", "Transaction Cancelled", "error");
      }
    });
  };

  return (
    <OctagonButton
      disabled={isProgress}
      onClick={handleApproveStake}
      className="bottom p-3 bg-[#4FD1C5] rounded-lg"
    >
      <p className="relative transition-all duration-300 text-white hover:text-[#0d0e38] focus:text-[#0d0e38] active:text-orange-400 font-semibold 2xl:text-base xl:text-base 2xlg:text-base text-xs">
        Approve
      </p>
    </OctagonButton>
  );
}
