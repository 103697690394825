import React from 'react';
import coins from '../../asset/image/Logo/coin.png';
import "./CoinRain.css"

const CoinRain = () => {
  return (
    <div className='pcoin-rain relative w-full h-full'>
        x
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />
      <img src={coins} alt="" />

    </div>
  );
}

export default CoinRain;
