/** @jsxImportSource @emotion/react */
import { useRef } from "react";
import { css } from "@emotion/react";
import {
  FrameSVGUnderline,
  useFrameSVGAssemblingAnimation,
} from "@arwes/react-frames";
import "../../utility/neon.css"

export const FrameUnderlines = () => {
  const svgRef = useRef(null);
  const { onRender } = useFrameSVGAssemblingAnimation(svgRef);

  return (
    <>
      <FrameSVGUnderline
        elementRef={svgRef}
        onRender={onRender}
        css={css`
  [data-name="bg"] {
    color: rgba(3, 121, 172, 0.59);
    transition: 'all 0.5s ease';
  },

  &:hover [data-name="bg"] {
    color: rgba(52, 186, 243, 0.8);
    filter: drop-shadow(0 0 4px #08f);
  },

  [data-name="line"] {
    color: #ddd;
    filter: drop-shadow(0 1px 4px #08f);
    transition: 'all 0.5s ease'
  },

  &:hover [data-name="line"] {
    color: rgba(0, 240, 253, 0.8);
    filter: drop-shadow(0 0 4px #08f);
  }
`}
        strokeWidth={3}
      />
    </>
  );
};
