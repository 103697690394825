import { useEffect, useState } from "react";
import axios from "axios";

const useFileLoadingProgress = (fileUrl) => {
  const [fileSize, setFileSize] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(fileUrl, { responseType: "blob" });
        const data = response.data;
        setFileSize(data.size);

        // Track the progress while downloading the file
        const totalBytes = data.size;
        let loadedBytes = 0;

        const updateProgress = (progressEvent) => {
          loadedBytes = progressEvent.loaded;
          setLoadingProgress((loadedBytes / totalBytes) * 100);
        };

        // Start downloading the file and update the progress
        await axios.get(fileUrl, {
          responseType: "blob",
          onDownloadProgress: updateProgress,
        });
      } catch (error) {
        setFileSize(null);
      }
    };
    fetchData();
  }, [fileUrl]);

  return { loadingProgress, fileSize };
};

export default useFileLoadingProgress;
