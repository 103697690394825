import React from "react";
import AnimatedNumbers from "react-animated-numbers";
import { CircleProgress } from "../../../components/progressBar/CircleProgress";
import { ClaimReward } from "../../../components/buttons/staking/claimReward";
import { Withdraw } from "../../../components/buttons/staking/withdraw";
import { OpenModalAddToken } from "../../../components/buttons/staking/OpenModalAddToken";
import { Rounded as Preloader } from "../../../components/utility/preloader";
import Soundbar from "../../../components/progressBar/soundbar";
import { useGetBlockNumber } from "../../../components/hooks/useGetBlockNumber";

export const StakeItemsBottomInfo = ({
  targetBlock,
  setAddModal,
  pendingReward,
  claimable,
  withdrawable,
  formatReward,
  isLoading,
  id,
  planId,
  refetchData
}) => {
  
  const currentBlock = useGetBlockNumber(planId); // Pass the planId to the custom hook
 
  return (
    <>
      <div className="content-btm grid grid-cols-2 border-2 border-[#4FD1C5] rounded-lg p-2 relative">
        <div className="rewards">
          <p className="text-xs text-start font-bold uppercase">
            Unclaimed
          </p>
          
          <div className="text-xl text-start font-bold font-play text-[#4FD1C5]">
          {isLoading ? (<div className="flex justify-start items-center px-3">
          <Preloader height={"50px"} width={"50px"} /></div>):
          (<span className="2xl:text-base xl:text-base lg:text-base text-sm">
          <AnimatedNumbers
                    includeComma
                    animateToNumber={pendingReward.reward}
                    locale="en-US"
                    configs={[
                      { mass: 1, tension: 220, friction: 100 },
                      { mass: 1, tension: 180, friction: 130 },
                      { mass: 1, tension: 280, friction: 90 },
                      { mass: 1, tension: 180, friction: 135 },
                    ]}
                  ></AnimatedNumbers> TWELVE</span>)}
          </div>
        </div>
        <div className="progress place-self-end">
          <p className="text-xs text-end font-bold uppercase">
            Calculate Progress
          </p>
          <div className="circles flex justify-center ">

            {planId === 1 && (<Soundbar/>) }
            {(planId === 2 || planId === 3) && (
            <CircleProgress targetBlock={targetBlock} currentBlock={currentBlock} planId={planId} />
          )}
          </div>
        </div>                
      </div>
      <div className="AddClaimWithdraw grid grid-cols-3 gap-4 p-3">
        {claimable && <ClaimReward id={id} refetchData={refetchData}/>}
        {withdrawable && <Withdraw id={id} refetchData={refetchData} />}
        <OpenModalAddToken onClick={() => setAddModal(true)} refetchData={refetchData} />
      </div>
    </>
  );
};
