import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { bsc, bscTestnet } from 'wagmi/chains'
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect'
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask'
import { InjectedConnector } from '@wagmi/core/connectors/injected'
import { StrictMode } from "react";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc],
  [publicProvider()],
)
const projectId = "b547b323abf46bafe7bde8761434043c";
const useInjected = new InjectedConnector({
  chains: chains,
  options: {
    shimDisconnect: true,
    name: (detectedName) =>
      `Injected (${
        typeof detectedName === 'string'
          ? detectedName
          : detectedName.join(', ')
      })`,
    getProvider: () =>
      typeof window !== 'undefined' ? window.ethereum : undefined,
  },
})

const useMetamask = new MetaMaskConnector({
  chains: chains,
  options: {
    shimDisconnect: false,
  },
})

const useWalletConnect = new WalletConnectConnector({
  chains: [bsc],
  options: {
    projectId: projectId,
    showQrModal: true,
  },
})

const config = createConfig({
  autoConnect: true,
  connectors: [
    useInjected,
    useMetamask,
    useWalletConnect
  ],
  publicClient,
  webSocketPublicClient,
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
  <WagmiConfig config={config}>
  <StrictMode>
      <App />
    </StrictMode>
    </WagmiConfig>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
