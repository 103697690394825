import React,{ useCallback, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { REACT_APP_API_ENDPOINT } from '../../config';

export const useFetchPlans = () => {
  const [plans, setPlans] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');


  const getStakingPlans = useCallback(async() => {
    setIsFetching(true);
    try {
      const stakePlans = await axios.get(REACT_APP_API_ENDPOINT +"/get-staking-plan");
      
      if (stakePlans?.data?.data) {
        setPlans(stakePlans.data.data);
        setError('');
       
      } else {
        setError('No staking plans available.');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops',
        html: error,
      });
      setError(error.message || 'An error occurred while fetching staking plans.');
    } finally {
      setIsFetching(false);
    }
  }, []);

  React.useEffect(()=> {
    getStakingPlans();
  },[getStakingPlans])

 

  return { plans, error, isFetching };
};
