/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef} from 'react';
import { FrameSVGNefrex, useFrameSVGAssemblingAnimation } from '@arwes/react-frames';

export const FrameHexagon = () => {
  const svgHexa = useRef(null);
  const { onRender } = useFrameSVGAssemblingAnimation(svgHexa);

  return (
    <div>
      <FrameSVGNefrex elementRef={svgHexa} onRender={onRender} css={css`
        [data-name="bg"] {
            color: rgba(3, 121, 172, 0.59);
              filter: 'drop-shadow(0 0 4px hsl(60, 75%, 10%))';
            },
            [data-name="line"] {
              color: rgba(0, 240, 253, 0.8);
              filter: 'drop-shadow(0 0 4px hsl(60, 75%, 50%))'
            } `}
            padding={1}
            strokeWidth={2}
            squareSize={10}
            smallLineLength={10}
            largeLineLength={50}/>
                
            
    </div>
  );
};

