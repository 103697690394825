import React, {useState, useEffect} from "react";
import { Dots as Preloader } from "../../../components/utility/preloader";
import { NFTSrc, Elements } from "../../../components/utility/mapZodiac";
import { useAllowanceNFT } from "../../../components/hooks/useAllowanceNFT";
import { ApproveBuy } from "../../../components/buttons/marketplace/approve";
import { SummonRites } from "../../../components/buttons/marketplace/buy";
import { formatUnits } from "viem";

const SummonNFT = ({ products, defaultChar, defaultEl,account }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { allowance, refetch } = useAllowanceNFT({account: account});
  const allowed = allowance !== undefined ? parseInt(formatUnits(allowance, 18)) : 0;
  const price = products?.price !== undefined ? parseInt(products?.price) : 0;

  useEffect(()=>{
    if(isLoading) {
      refetch();
      setIsLoading(false);
    }
  },[isLoading, refetch])


  return (
    <div className="summon mt-16 w-[screen] h-14 flex justify-center items-center place-content-center place-items-center">
      <div
        className="id translate-x-3 w-full pr-5 flex flex-col justify-center items-end place-self-center"
        style={{
          width: "150px",
          height: "100px",
          background: "rgba(3,105,161,0.8)",
        }}
      >
        <p className="token-id text-end text-sm z-[5] w-full">
          Token ID {products.id}
        </p>
        <p className="text-sm font-semibold z-[5]">
          {NFTSrc[defaultChar - 1]?.title ?? ""}{" "}
          {Elements[defaultEl - 1]?.name ?? ""}
        </p>
      </div>
      <div className="btn-container flex justify-center z-10 ">
      {isLoading ? (
    <div className="summoner bg-red-500 2xl:w-[200px] xl:w-[200px] lg:w-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] w-[150px] h-[150px] flex justify-center items-center">
    <span className="text-3xl text-white z-[5]">
      <Preloader height={"200px"} width={"200px"} />
    </span>
  </div>  
  ) : (
    allowed !== undefined && allowed >= price ? (
      <SummonRites id={products?.id} cost={products?.price}/>
    ) : (
      <ApproveBuy amount={products?.price} setIsLoading={setIsLoading}/>
    )
  )}

      </div>
      <div
        className="mana -translate-x-3 w-full pl-5 flex flex-col justify-center items-start place-self-center"
        style={{
          width: "150px",
          height: "100px",
          background: "rgba(3,105,161,0.8)",
        }}
      >
        <p className="text-sm z-[5]">MANA COST</p>
        <p className="mana-price text-lg font-semibold z-[5]">
          {products.price} USDT
        </p>
      </div>
    </div>
  );
};

export default SummonNFT;
