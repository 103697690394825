import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getWalletClient, readContract } from '@wagmi/core';
import { REACT_APP_API_ENDPOINT, NFTABI, NFTADDR } from '../config';
import { Elements } from '../components/utility/mapZodiac';
import { TitleCollection } from './pageComponents/collectionPageComponents/titleCollection';
import { ItemCollection } from './pageComponents/collectionPageComponents/itemCollection';
import './collection.css';
import Swal from 'sweetalert2';
import { Blocks as Preloader } from '../components/utility/preloader';

const Collection = () => {
  const [nftData, setNftData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const walletClient = await getWalletClient();
      

      if (!walletClient || !walletClient.account || !walletClient.account.address) {
        // The walletClient or account is not available yet, wait and try again
        setTimeout(fetchData, 1000);
        return;
      }
      const account = walletClient?.account?.address;

      try {
        const res = await axios.get(`${REACT_APP_API_ENDPOINT}/getNftByAddress?address=${account}`);
        const nftsByAccount = res.data?.data;
       
        if (JSON.stringify(nftData) === JSON.stringify(nftsByAccount)) {
          setIsLoading(false);
          return;
        }
        
        const tokenIds = nftsByAccount.map((item) => item.tokenid);
        const totalTokens = tokenIds.length;
        let completedTokens = 0;
       

        const getTokenPromises = tokenIds.map((tokenid) =>
          readContract({
            address: NFTADDR,
            abi: NFTABI,
            functionName: 'getTokenData',
            args: [tokenid],
            account: walletClient.account,
          }).finally(() => {
            completedTokens++;
            setLoadingProgress((completedTokens / totalTokens) * 100);
          })
        );
       
        const tokenData = await Promise.all(getTokenPromises);
        
        const processedNfts = nftsByAccount.map((item, index) => {
          const {
            
              attribute: [{ value: chinese }, { value: element }, , , { value: str }, { value: wks }, { value: years }, ,],
              desc,
            
            title,
            price,
            cdn,
            file,
            tokenid,
          } = item;

          const elementIMG = Elements.find(
            (el) => el.name.toLowerCase() === element.toLowerCase()
          )?.src;

          const titleContent = title.split('(')[1]?.slice(0, -1);
          const chineseCharacters = chinese.charAt(0);
          const chineseRomanization = chinese.slice(2, -1).replace(/[()]/g, '');

          return {
            tokenid,
            price,
            cdn,
            file,
            title: titleContent,
            chineseChar: chineseCharacters,
            chineseRoman: chineseRomanization,
            element,
            elementIMG,
            str,
            wks,
            years,
            desc,
            code: tokenData[index]?.code,
          };
        });
        setNftData(processedNfts);
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: 'Server is not Responding',
          text: 'Please wait while maintenance is over',
        });
        console.log(err)
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000); // Add a 2-second delay before hiding the progress bar
      }
    };

    if (!nftData && !isLoading) {
      fetchData();
    }
  }, [nftData, isLoading]);

  if (isLoading) {
    return (
      <main className="Main">
      <div className="fixed-layer-collection"></div>
      <div className="scrollable-layer z-10">
        <div className="wrapper absolute z-20 w-full h-full">
          <section className="section 2xl:w-[80%] xl:w-[80%] lg:w-[80%] w-[90%] mx-auto">
            <div className="loading h-screen flex flex-col justify-center items-center">
            <Preloader height={"200px"} width={"200px"} />
                <p className="animate-pulse text-3xl font-bold">
                  Loading Collection Data... ({loadingProgress.toFixed(2)}%)
                </p>
      </div>
          </section>
        </div>
      </div>
    </main>
    );
  }

  return (
    <main className="Main">
      <div className="fixed-layer-collection"></div>
      <div className="scrollable-layer z-10">
        <div className="wrapper absolute z-20 w-full h-full">
          <section className="section 2xl:w-[80%] xl:w-[80%] lg:w-[80%] w-[90%] mx-auto">
            <TitleCollection />
            <div className="inventory-container h-[650px] overflow-auto">
              <div className="inventory-box w-full overflow-y-scroll grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 grid-cols-1 gap-5">
                {nftData &&
                  nftData.map((item) => (
                    <ItemCollection key={item.tokenid} data={item} />
                  ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default Collection;
