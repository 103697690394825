import React, { useState } from 'react';
import {PlanButton} from '../../../components/buttons/staking/plan';

export const StakeItemsPlanSelect = ({ plans, selectPlan }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePlanClick = (planId) => {
    selectPlan(planId);
    setSelectedPlan(planId);
  };

  return (
    <div className="btn-plan grid grid-cols-3 gap-3 z-[1000] pointer-events-auto">
      {plans.map((nftPlan) => (
        <PlanButton
          key={nftPlan.id}
          isSelected={selectedPlan === nftPlan.id}
          title={nftPlan.title}
          onClick={() => handlePlanClick(nftPlan.id)}
        />
      ))}
    </div>
  );
};
