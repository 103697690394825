import React from 'react'
import "./summoningCircle.css";
export const SummonRoundTable = () => {
  return (
    <div className="round-table w-screen h-[300px] bg-no-repeat bg-auto left-0 2xl:top-[500px] xl:top-[500px] lg:top-[450px] top-[325px]">
        <div className="summoning-circle 2xl:w-[600px] xl:w-[300px] lg:w-[300px] w-[300px] 2xl:h-[400px] xl:h-[300px] lg:h-[300px] h-[300px] ">
          <div className="stress-dots">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>
      </div>
  )
}
