import React, { useEffect, useState } from "react";
import { NFTABI, NFTADDR } from "../../../config";
import {
  prepareWriteContract,
  writeContract,
  waitForTransaction,
} from "@wagmi/core";
import Swal from "sweetalert2";
import { useFetchBalance } from "../../hooks/useFetchBalance";

export const SummonRites = ({ id, cost }) => {
  const [isProgress, setIsProgress] = useState(false);
  const [parsedToken, setParsedToken] = useState(0);
  const [parsedCost, setParsedCost] = useState(0);
  const [errors, setErrors] = useState();
  // const [btnDIsabled, setBtnDisabled] = useState(true);
  const { token } = useFetchBalance();
  // console.log(typeof token.formatted, typeof cost)

  useEffect(() => {
    if (token && cost) {
      setParsedToken(parseFloat(token?.formatted));
      setParsedCost(parseFloat(cost));
    }
  }, [token,cost]);

  const handleBuyNFT = async () => {
    if (isProgress) return;
    setIsProgress(true);

    if (parsedToken < parsedCost) {
      Swal.fire({
        icon: "error",
        title: "Not enough Balance",
        html: `Your balance is not enough`,
      });
      setIsProgress(false);
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      html: ` Summon is using ${cost} USDT`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Summon it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      reverseButtons: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // User clicked "Summon"
        setIsProgress(true);
        try {
          // Perform the buying transaction
          const { request } = await prepareWriteContract({
            address: NFTADDR,
            abi: NFTABI,
            functionName: "BuyItem",
            args: [id],
          });
          Swal.fire({
            title: "Please Wait",
            html: "Connecting to <strong classNames='text-blue-900'>Network...</strong>",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          const { hash } = await writeContract(request);
          Swal.fire({
            title: "Please Wait",
            html: "Writing Transaction...",
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          const data = await waitForTransaction({ hash });
          if (data) {
            const bscScanLink = `https://bscscan.com/tx/${hash}`;
            Swal.fire({
              icon: "success",
              title: "Horray..",
              html: `Transaction success! <a href=${bscScanLink} target="_blank" rel="noopener noreferrer"><u><b>View Here</b> </u></a>`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: "Transaction failed or was canceled",
            });
            setIsProgress(false);
          }
        } catch (error) {
          setErrors(error);
          console.log(errors);
          if (error.details) {
            Swal.fire({
              icon: "error",
              title: "Transaction Cancelled",
              html: `${error.details}`,
            });
          } else if (error.shortMessage) {
            Swal.fire({
              icon: "error",
              title: "Transaction Cancelled",
              html: `${error.shortMessage}`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error on Buy Function",
              html: `Contact support@twelvezodiac.co `,
            });
          }
          setIsProgress(false);
        } finally {
          setIsProgress(false);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked "No, cancel!"
        Swal.fire("Cancelled", "Transaction Cancelled", "error");
        setIsProgress(false);
      }
    });
  };
  return (
    <>
      <button
        disabled={isProgress}
        onClick={handleBuyNFT}
        className="summoner relative 2xl:w-[200px] xl:w-[200px] lg:w-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] w-[150px] h-[150px] flex justify-center items-center"
      >
        <p className="text-3xl text-white z-[5]">BUY</p>
      </button>
    </>
  );
};
