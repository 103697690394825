import React, { useState, useRef, useEffect } from "react";
import { StakeItemsPlanSelect } from "././stakeItemsPlanSelect";
import { StakeItemsApproveStake } from "./stakeItemsApproveStake";
import { StakeItemsInfo } from "./stakeItemsInfo";
import { StakeItemsNFTTitle } from "./stakeItemsNFTTitle";
import { StakeItemsFuel } from "./stakeItemsFuel";
import { AnimatedBackground } from "../../../components/arwes/animated/animatedBackground";
import { StakeItemsFormInput } from "./stakeItemsFormInput";
import { StakeItemsBottomInfo } from "./stakeItemsBottomInfo";
import { formatUnits } from "viem";
import { useAllowanceStaking } from "../../../components/hooks/useAllowanceStaking";
import { Rounded as Preloader } from "../../../components/utility/preloader";
import { AddToken } from "../../../components/modal/modalAddStake";
import { REACT_APP_API_ENDPOINT } from '../../../config';
import axios from 'axios';

export const StakingItems = ({ data, plans, account, tokenid, refetchData }) => {
  const videoRef = useRef(null);
  const [amount, setAmount] = useState();
  const [selectPlan, setSelectPlan] = useState();
  const [stakesAmount, setStakesAmount] = useState(0);
  const [needApprove, setNeedApprove] = useState(true);
  const [loading, setLoading] = useState(false)
  const [addModal, setAddModal] = useState(false)

  const minAmount = parseFloat(formatUnits("100000000000000000", 18));
  const maxAmount = parseFloat(data?.max ? formatUnits(data?.max, 18) : 0);
  const { allowance } = useAllowanceStaking({ account: account });
  const [error, setError] = useState('');
  const [pendingReward, setPendingReward] = useState({
    reward: 0,
    claimable: false,
    withdrawable: false,
    pendingStatus: false,
  });
  // const [formatReward, setFormatReward] = useState();
  const [isLoading, setIsLoading] = useState(true);
  
  const closeAddModal = () => {
    setAddModal(false);
  };

  const handleClickPlan = (id) => {
    setSelectPlan(id);
  };
  const handleVideoLoad = () => {
    setLoading(true);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute('playsinline', '');
    }
  }, []);

  // const planID = data.planId;
  const getPendingReward = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await axios.get(
        REACT_APP_API_ENDPOINT+ "/getStakePendingReward?tokenid="+ tokenid
      );

      if (result.data.success === true) {
        const formatted = result.data?.data.reward;
        setPendingReward({
          reward: result.data?.data ? formatUnits(formatted, 18) : 0,
          claimable: result.data?.data.claimable,
          withdrawable: result.data?.data.withdrawable,
          pendingStatus: result.data?.success,
        });

        // Fix the calculation of formatReward
        // if (planID === 1) {
        //   const formattedOne = parseFloat(pendingReward.reward) * 0.6; // 60%
        //   setFormatReward(formattedOne);
        // } else if (planID === 2) {
        //   const formattedTwo = parseFloat(pendingReward.reward) * 0.85; // 85%
        //   setFormatReward(formattedTwo);
        // } else {
        //   setFormatReward(parseFloat(pendingReward.reward));
        // }
      } else {
        setError('Fetching pending rewards failed');
      }
    } catch (err) {
      setError(error.message || 'An error occurred while fetching pending rewards.');
    } finally {
      setIsLoading(false);
    }
  }, [error.message, tokenid]);
  
  React.useEffect(()=>{
    getPendingReward()
  },[getPendingReward])

  return (
    <div className="item 2xl:border-[#4FD1C5] xl:border-[#4FD1C5] lg:border-[#4FD1C5] 2xl:border xl:border lg:border h-full 2xl:w-[33.2%] xl:w-[33.2%] lg:w-[33.2%] w-full relative">
      <AnimatedBackground />
      <div className="content p-2 flex flex-col relative">
      <AddToken 
                show={addModal} 
                onClose={closeAddModal} 
                id={tokenid}
                max={maxAmount}
                min={minAmount}
                allowance={allowance}
                refetchData={refetchData}
                />
        <div className="top relative">

          <div className="StakeByNFTInfo w-full grid grid-cols-3 gap-3 mb-3">
            <StakeItemsInfo
              amount={data.amount}
              planId={data.planId}
              earned={data.claimedReward}
            />
          </div>

          <div className="content-top relative flex justify-center items-center">
            
            <div className={`loader my-8 ${loading ? "hidden" : ""}`}>
              <Preloader height={"200px"} width={"200px"} />
              <p className="loader animate-bounce text-center text-md font-bold text-white">
                Loading..
              </p>
            </div>

            <video
              ref={videoRef}
              autoPlay="false"
              loop="loop"
              muted="muted"
              src={data.cdn}
              className={`NFT ${loading ? "" : "hidden"}`} // Hide the video when loading is true
              onLoadedData={handleVideoLoad}
            >
              <source src={data.cdn} />
            </video>
            <div className="NFTTitle w-full flex justify-between items-center absolute top-0">
              <StakeItemsNFTTitle tokenid={tokenid} title={data.title} />
            </div>
          </div>
        </div>
        <div className="middle relative">
          <StakeItemsFuel fuel={data.fuel} refetchData={refetchData} />
          
        </div>

        <div className="bottom relative">
          {data.planId > 0  ? (
            <StakeItemsBottomInfo
              targetBlock={data.endBlock}
              pendingReward={pendingReward}
              claimable={pendingReward.claimable}
              withdrawable={pendingReward.withdrawable}
              // formatReward={formatReward}
              setAddModal={setAddModal}
              isLoading={isLoading}
              id={tokenid}
              planId={data.planId}
              refetchData={refetchData}
            />
          ) : (
            <div className="bottom-content grid grid-cols-1 gap-2">
              <StakeItemsPlanSelect
                plans={plans}
                selectPlan={handleClickPlan}
              />
              <StakeItemsFormInput
                max={maxAmount}
                min={minAmount}
                allowance={allowance}
                setStakesAmount={setStakesAmount}
                stakesAmount={stakesAmount}
                setNeedApprove={setNeedApprove}
                setAmount={setAmount}
                amount={amount}
              />
              <StakeItemsApproveStake
                selectPlan={selectPlan}
                needApprove={needApprove}
                setNeedApprove={setNeedApprove}
                stakesAmount={stakesAmount}
                allowance={allowance}
                id={tokenid}
                min={minAmount}
                max={maxAmount}
                refetchData={refetchData}
              />
             
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
