import React, { useState } from "react";
import { prepareWriteContract, writeContract, waitForTransaction } from "@wagmi/core";
import Swal from "sweetalert2";
import { STAKEADDR, STAKEABI } from "../../../config";
import { OctagonButton } from "../../arwes/Buttons/OctagonButton";
import { formatUnits } from 'viem';

export function Staking({ stakesAmount, selectPlan, id, allowance, min, max, refetchData }) {
  const [error, setError] = useState();
  const [isProgress, setIsProgress] = useState(false);

  const handleStake = async () => {
    if (isProgress) return;
    setIsProgress(true);
    const formatStakesAmount = formatUnits(stakesAmount, 18)
    const parsedStakesAmount = parseFloat(formatStakesAmount);

    if(parsedStakesAmount < min) {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: `Minimum amount is ${min}`,
      });
      setIsProgress(false);
      return;
    }

    if(parsedStakesAmount > max) {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: `Maximum amount is ${max}`,
      });
      setIsProgress(false);
      return;
    }

    if(!selectPlan) {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please Select Plan for Earning",
      });
      setIsProgress(false);
      return;
    }
    if(parsedStakesAmount > allowance) {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "The Amount is exceeding the Allowanced Amount",
      });
      setIsProgress(false);
      return;
    }
    try {
      const { request } = await prepareWriteContract({
        address: STAKEADDR,
        abi: STAKEABI,
        functionName: "Stake",
        args: [stakesAmount, selectPlan, id],
      });

      Swal.fire({
        title: "Please Wait",
        html: "Connecting to Blockchain...",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const { hash } = await writeContract(request);

      Swal.fire({
        title: "Please Wait",
        html: "Writing Transaction...",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const trx = await waitForTransaction({
        hash,
      });

      if (trx) {
        const bscScanLink = `https://bscscan.com/tx/${hash}`;
        Swal.fire({
          icon: "success",
          title: "Horray..",
          html: `Transaction success! <a href=${bscScanLink} target="_blank" rel="noopener noreferrer"><u><b>View Here</b> </u></a>`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: "Transaction failed or was canceled",
        });
        setIsProgress(false);
      }
    } catch (err) {
      setError(err);
      console.log(error);
      if (error) {
        if (error.details || error.shortMessage) {
          Swal.fire({
            icon: "error",
            title: "Transaction Cancelled",
            html: `${error.details || error.shortMessage}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error on Earning Function",
            html: "Please contact support@twelvezodiac.co",
          });
        }
      }
      
      setIsProgress(false);
    } finally {
      setIsProgress(false);
      refetchData(); //it was supposed to refetchData
    }
  };

  return (
    
      <OctagonButton
        disabled={isProgress}
        onClick={handleStake}
        className="bottom p-3 bg-[#4FD1C5] rounded-lg"
      >
        <p className="relative transition-all duration-300 text-white hover:text-[#0d0e38] focus:text-[#0d0e38] active:text-orange-400 font-semibold 2xl:text-base xl:text-base 2xlg:text-base text-xs">
          Earning
        </p>
      </OctagonButton>
    
  );
}
