import { useState, useEffect } from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { formatUnits } from "viem";
import cny from "../../asset/image/ied01.jpg";
import cny2 from "../../asset/image/ied02.jpg";
import "./Giveaway.css";
import { REACT_APP_API_ENDPOINT } from "../../config";
import axios from "axios";
import CoinRain from "../utility/CoinRain";

const Giveaway = ({ account, claimed, setClaimed }) => {
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [seconds, setSeconds] = useState(15);

  // console.log(!claimed?.data?.data?.value, "lose");
  // console.log(claimed?.data?.data?.value > 0, "win");
  // console.log(claimed?.data?.success === false);

  const handleClaimGift = async () => {
    setLoading(true);
    try {
      const data = { address: account };
      const response = await axios.post(
        REACT_APP_API_ENDPOINT + "/events/ramadhan2024/claim",
        data,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setClaimed(response);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (claimed?.data?.success === true && claimed?.data?.data?.value > 0) {
      setResult("win");
    }
    if (
      claimed?.data?.success === true &&
      claimed?.data?.data?.message === "Sorry, please try later next event."
    ) {
      setResult("lose");
    }
    if (claimed?.data?.success === false) {
      setResult("claim");
    }
  }, [claimed]);

  useEffect(() => {
    if (result && seconds > 0) {
      const timer = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1500);
      return () => clearTimeout(timer);
    } else if (seconds === 0) {
      window.location.reload();
    }
  }, [seconds, result]);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="contain relative w-full h-full flex justify-center items-center">
      {result === "win" ? (
        <button className="w-[95%] h-auto bg-black/15 p-2">

          <img
            src={cny2}
            alt=""
            className={`w-full h-full hover:drop-shadow-[#D0B050] rounded-3xl ${
              loading ? "spin" : ""
            }`}
          />
        </button>
      ) : (
        <button
          className="w-[95%] h-auto bg-black/15 p-2 relative flex justify-center items-center"
          disabled={loading}
          onClick={handleClaimGift}
        >
          <img
            src={cny}
            alt=""
            className={`w-full h-full hover:drop-shadow-[#D0B050] rounded-3xl ${
              loading ? "spin" : ""
            }`}
          />
          <p className="absolute px-4 py-2 text-3xl font-bold bg-green-900 rounded-lg animate-pulse" > {loading ? "Processing...":"CLICK HERE !"} </p>
        </button>
      )}
      <div className="content flex flex-col items-center justify-center px-4 py-8 w-full h-full absolute inset-0  pointer-events-none z-[1100]">
        {claimed && (
          <div>
            {result === "win" && (
              <div className="claimed ">
                <CoinRain/>
                <div className="info bg-black/30 rounded-xl p-5 backdrop-blur-sm">
                  <p className="2xl:text-4xl xl:text-4xl lg:text-4xl text-2xl font-black">
                  Happy Eid Mubarak 2024!!
                  </p>
                  <p className="2xl:text-3xl xl:text-3xl lg:text-3xl text-xl font-black">Congratulations !!!</p>
                  <p className="font-black 2xl:text-xl xl:text-xl lg:text-lg text-base text-white">
                    You got{" "}
                    <span className="font-bold">
                      {claimed?.data?.data?.value
                        ? formatUnits(claimed?.data?.data?.value, 18)
                        : "0.00"}{" "}
                      $TWELVE as a <strong>GIVEAWAY</strong>{" "}
                    </span>
                  </p>
                  <div className="refresh pointer-events-auto">
                  <span className="text-xl mt-3">Page will refresh in {seconds} seconds or click <button onClick={handleRefresh} className="font-black underline hover:text-blue-700">Refresh</button> </span>
                  </div>
                </div>
              </div>
            )}

            {result === "lose" && (
              <div className="claimed">
                <div className="info bg-black/30 rounded-xl p-5 backdrop-blur-sm">
                  <p className="text-4xl font-black text-white drop-shadow-[0_0px_5px_rgba(255,255,255,1)]">
                  Happy Eid Mubarak 2024!!
                  </p>
                  <p className="font-black text-xl text-white">
                    Thank you for participating in our giveaway, but
                    unfortunately you didn't win anything. Don't be sad, we will
                    have another giveaway.
                  </p>
                  <div className="refresh pointer-events-auto">
                  <span className="text-xl mt-3">Page will refresh in {seconds} seconds or click <button onClick={handleRefresh} className="font-black underline hover:text-blue-700">Refresh</button> </span>
                  </div>
                </div>
              </div>
            )}
            {result === "claim" && (
              <div className="claimed">
                <div className="info bg-black/30 rounded-xl p-5 backdrop-blur-sm">
                  <p className="text-4xl font-black text-white drop-shadow-[0_0px_5px_rgba(255,255,255,1)]">
                  Happy Eid Mubarak 2024!!
                  </p>
                  <p className="font-black text-xl text-white">
                    Thank you for participating in our giveaway, but
                    unfortunately{" "}
                    <span className="text-2xl">
                      you already claim the reward
                    </span>{" "}
                    . Don't be sad, we will have another giveaway soon.
                  </p>
                  <div className="refresh pointer-events-auto">
                  <span className="text-xl mt-3">Page will refresh in {seconds} seconds or click <button onClick={handleRefresh} className="font-black underline hover:text-blue-700">Refresh</button> </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {/* <img src={snowman} alt="" /> */}
    </div>
  );
};

export default Giveaway;
