import React, { useState, useRef, useEffect } from "react";
import { FrameLines } from "../../../components/arwes/frames/frameLines";
import { FrameCorners } from "../../../components/arwes/frames/frameCorners";
import LazyLoad from "../../../components/utility/LazyLoad";
import { Rounded as Preloader } from "../../../components/utility/preloader";
export const ItemCollection = ({ data }) => {
  const [loading, setLoading] = useState(false); // Add a loading state
  const videoRef = useRef(null);
  const handleVideoLoad = () => {
    setLoading(true);
  };
 
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute('playsinline', '');
    }
  }, []);

  return (
    <div className="inventory-items w-full 2xl:h-[650px] xl:h-[650px] lg:h-[650px] h-full pb-4 relative z-0">
      <FrameLines />
      <div className="card z-[100] w-full relative">
        <div className="card-wrapper">
          <div className="top flex justify-between p-2  ">
            <div className="top-layer1 h-[100px] w-[100px] flex flex-col justify-center items-center bg-sky-800">
              <div className="content-tokenid 0">
                <p className='text-2xl font-bold font-play'>{data.tokenid}</p>
                <p className="text-xs text-start font-bold">Token ID</p>
              </div>
            </div>
            <div className="content-title flex flex-col justify-center items-end w-full ">
              <p className="text-xl font-semibold">{data.title}</p>
              <p className="text-md ">{data.element}</p>
              <p className="text-lg font-semibold">{data.code}</p>
            </div>
          </div>
          <div className="middle w-[96%] mx-auto ">
            <div className="NFT relative">
              <FrameCorners />
              <div className={`loader my-8 ${loading ? "hidden" : ""}`}>
                <Preloader height={"100px"} width={"100px"} />
                <p className="loader animate-bounce text-center text-md font-bold text-white">
                  Loading..
                </p>
              </div>
              <div className="content">
                <video
                  ref={videoRef}
                  autoPlay="false"
                  loop="loop"
                  muted="muted"
                  src={data.cdn}
                  className={`NFT relative p-[1px] ${loading ? "visible" : "invisible"}`} // Hide the video when loading is true
                  onLoadedData={handleVideoLoad}
                >
                  <source src={data.cdn} />
                </video>
                <div className="element-symbol top-2 left-2 absolute h-[60px] w-[60px] rounded-full p-1 bg-sky-600">
                    <LazyLoad src={data.elementIMG}/>
                </div>
                <div className="element-symbol bottom-2 right-2 absolute h-[90px] w-[80px] rounded-md p-1 bg-sky-600 flex flex-col justify-center items-center">
                    <p className="font-noto font-bold text-4xl text-black">{data.chineseChar}</p>
                    <p className="font-play font-semibold text-base">{data.chineseRoman}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom p-2 w-[96%] 2xl:h-[245px] xl:h-[245px] h-full mx-auto relative mt-3">
            <FrameCorners />
            <div className="info-container relative p-1 flex flex-1 flex-col gap-[2px]">
                <p className="text-end font-semibold">Zodiac Info</p>
                <p className="text-justify text-sm py-1" >{data.desc}</p>
             
                <p className="text-start text-sm py-1">Strength: {data.str}</p>
                <p className="text-start text-sm py-1">Weakness: {data.wks}</p>
                <p className="text-start text-sm py-1">Years : {data.years}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
