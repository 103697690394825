import { NumericFormat } from "react-number-format";
import { FrameHexagon } from "../arwes/frames/frameHexagon";
import { useFetchBalance } from "../hooks/useFetchBalance";


export function MarketBalanceDesktop () {
    const { usdt } = useFetchBalance();

    return (
    <div className="balances fixed w-[225px] flex flex-col items-end right-6 top-[80px]">
    <div className="wrapper">
      <FrameHexagon />
     <NumericFormat
        value={usdt?.formatted}
        displayType="text"
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={4}
        renderText={(value) => (
          <p className="font-semibold px-4 py-3 2xl:text-lg xl:text-lg lg:text-lg text-sm">
            {value} {usdt?.symbol}
          </p>
        )}
      />
    </div>
  </div>
  )
}

export function StakingBalanceDesktop () {
    const { token, bnb } = useFetchBalance();

    return (
    <div className="balances fixed w-[225px] flex flex-col items-end right-6 top-[80px]">
    <div className="wrapper relative w-full">
      <FrameHexagon />
      <NumericFormat
        value={token?.formatted}
        displayType="text"
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={4}
        renderText={(value) => (
          <p className="font-semibold px-4 py-3 2xl:text-lg xl:text-lg lg:text-lg text-sm">
            {value} {token?.symbol}
          </p>
        )}
      />
    </div>
    <div className="wrapper relative w-full">
      <FrameHexagon />
      <NumericFormat
        value={bnb?.formatted}
        displayType="text"
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={4}
        renderText={(value) => (
          <p className="font-semibold px-4 py-3 2xl:text-lg xl:text-lg lg:text-lg text-sm">
            {value} {bnb?.symbol}
          </p>
        )}
      />
    </div>
  </div>
  )
}