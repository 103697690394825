import React from "react";
import { FaRegStickyNote, FaCheckCircle } from "react-icons/fa";
import { Text } from "@arwes/react";
import HexagonButton from "../../arwes/Buttons/HexagonButton";

export const BtnNavInfo = ({ copyAddress, address, copied }) => {
  return (
    <>
      <HexagonButton
        as="a"
        title="View on Bscscan"
        href={`https://bscscan.com/address/${address}`}
        target="_blank"
        rel="noopener noreferrer"
        className="2xl:w-[160px] xl:w-[160px] lg:w-[160px] w-[110px]"
      >
        <Text
          style={{ color: "white" }}
          className='font-["Play"] 2xl:text-base xl:text-base lg:text-base text-xs drop-shadow-[0_1px_5px_rgb(255,255,255)]'
        >
          View Address
        </Text>
      </HexagonButton>
      <HexagonButton
        onClick={copyAddress}
        className="2xl:w-[160px] xl:w-[160px] lg:w-[160px] w-[110px]"
      >
        <Text
          style={{ color: "white" }}
          className='font-["Play"] 2xl:text-base xl:text-base lg:text-base text-xs drop-shadow-[0_1px_5px_rgb(255,255,255)]'
        >
          <span className="inline-flex gap-1 items-center ">
            {copied ? "Copied!" : "Copy"}
            {copied ? <FaCheckCircle /> : <FaRegStickyNote />}
          </span>
        </Text>
      </HexagonButton>
    </>
  );
};
