import React from "react";
import { FrameHexagon } from "../frames/frameHexagon";

export function HexagonButton({
  type = "button",
  onClick,
  as: Component = "button",
  children,
  ...rest
}) {
  const buttonStyles = {
    position: "relative",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...rest.style, // Allow additional styles to be passed via props
  };

  return (
    <Component
      onClick={onClick}
      style={buttonStyles}
      {...rest}
      
    >
      <FrameHexagon />
     {children}
    </Component>
  );
}

export default HexagonButton;
