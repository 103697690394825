import React,{useState, useEffect} from 'react'
import './startPage.css';
import { Animator } from '@arwes/react-animator';
import { Dots } from '@arwes/react-bgs';
import StartLoad from './pageComponents/startPageComponents/start';

const FixedLayer = () => {
  const [active, setActive] = useState(true);

  useEffect(() => {
    const iid = setInterval(() => setActive(active => !active), 1000);
    return () => clearInterval(iid);
  }, []);

    return (
      <div className="fixed-layer-dashboard">
       <Animator active={active} combine manager='sequence' duration={{ enter: 1, exit: 2 }}>
      <div className=' h-[500px] w-[500px] rounded-full relative bg-transparent overflow-clip'>
        {/* Canvas element will ocupy the positioned parent element. */}
        
        <Dots
          color='hsla(180, 100%, 75%, 0.4)'
        />
       

      </div>
    </Animator>
      </div>
    );
  };

const Start = () => {


  
  return (
    <div className="Main">
      <FixedLayer />
     
      <div className="scrollable-layer z-10">
      
       <div className="wrapper fixed z-20 w-full h-screen">
        <section className="section">
          <StartLoad/>
        </section>


        </div>
      </div>
    </div>
  )
}

export default Start;