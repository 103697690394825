import React from 'react'
import { FaWallet } from "react-icons/fa";

export const ConnectDesktop = ({onClick}) => {
  return (
    <div className="connect font-['Play']">
                <button  onClick={onClick} className="h-[50px] px-9 border-2 border-blue12 border-t-0 border-r-0 rounded-bl-3xl rounded-tr-3xl  slide-box-light slide_right flex justify-center items-center text-lg text-blue12 hover:text-yellow-900">
                 <span className="inline-flex items-center gap-3"> <FaWallet /> Connect Wallet </span>
                </button>
              </div> 
  )
}
