import React from "react";
import {OctagonButton} from "../../arwes/Buttons/OctagonButton";
export const OpenModalAddToken = ({onClick}) => {

  return (
   
    <OctagonButton
    
    onClick={onClick}
    className="bottom p-3 bg-[#4FD1C5] rounded-lg"
  >
    <p className="relative transition-all duration-300 text-white  hover:text-[#0d0e38]   focus:text-[#0d0e38]   active:text-orange-400 font-semibold">
      Add
    </p>
  </OctagonButton>
      
    
  );
};
