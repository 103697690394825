import React, {useState, useEffect} from 'react'
import "./CircleProgress.css";
export const CircleProgress = ({ currentBlock = 0, targetBlock = 1, planId}) => {
  const [finalBlock, setFinalBlock] = useState()
   // Ensure targetBlock is not 0 to avoid division by zero
  if (targetBlock === 0) {
    targetBlock = 1;
  }
  useEffect(()=>{
    if (planId === 2) {
      setFinalBlock(2620800);
      
     }
   
     if (planId === 3) {
       setFinalBlock(5241600);
   
     }
  },[planId])


  // Calculate the percentage of remaining blocks to reach the targetBlock
  // const remainingBlocks = Math.max(0, targetBlock - currentBlock);
  // const percentage = Math.min(100, Math.max(0, (remainingBlocks / finalBlock) * 100));
  const percentage = (100-(((targetBlock-currentBlock)/finalBlock)*100)).toFixed(2)
 
  return (
    <div className='percent' style={{"--clr":"#04fc43", "--num": percentage}}>
        <div className="dot "></div>
        <svg>
            <circle cx={45} cy={45} r={45}></circle>
            <circle cx={45} cy={45} r={45}></circle>
    
        </svg>
        <div className='number absolute inset-0 flex flex-col justify-center'>
            <p className='font-bold'>{percentage} <span>%</span> </p>
            <p className='text-sm'>Finish</p>
        </div>
    </div>
  )
}
