import { TOKENADDR, TOKENABI, STAKEADDR } from "../../config";
import { useContractRead } from 'wagmi'

export const useAllowanceStaking = ({account}) => {

  const { data:result, isLoading, isFetching, isFetched, isRefetching, status, isError, error, refetch, isIdle } = useContractRead({
    address: TOKENADDR,
    abi: TOKENABI,
    functionName: "allowance",
    args: [account, STAKEADDR],
    watch:true
  });
  
  return {
    allowance: result,
    isLoading: isLoading,
    isFetching: isFetching,
    isFetched: isFetched,
    isRefetch: isRefetching,
    refetch: refetch,
    error:error,
    isError: isError,
    isIdle:isIdle,
    status:status
  };
};