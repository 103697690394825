/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef } from 'react';
import { FrameSVGLines, useFrameSVGAssemblingAnimation } from '@arwes/react-frames';

export const FrameLines = ({children}) => {
  const svgRef = useRef(null);
  const { onRender } = useFrameSVGAssemblingAnimation(svgRef);

  return (
    <div >
      <FrameSVGLines
        elementRef={svgRef}
        onRender={onRender}
        css={css`
      [data-name="bg"] {
        color: rgba(3, 121, 172, 0.59);
        filter: 'drop-shadow(0 0 4px hsl(60, 75%, 10%))';
        backdrop-filter: blur(10px);
      },
      [data-name="line"] {
        color:  rgba(0, 240, 253, 0.8);
        filter: 'drop-shadow(0 0 4px hsl(60, 75%, 50%))';
      `}
      >
        {children}
      </FrameSVGLines>
    </div>
  );
};

