import RAT from "../../asset/image/webp/1_Rat.webp";
import OX from "../../asset/image/webp/2_Ox.webp";
import TIGER from "../../asset/image/webp/3_Tiger.webp";
import RABBIT from "../../asset/image/webp/4_Rabbit.webp";
import DRAGON from "../../asset/image/webp/5_Dragon.webp";
import SNAKE from "../../asset/image/webp/6_Snake.webp";
import HORSE from "../../asset/image/webp/7_Horse.webp";
import GOAT from "../../asset/image/webp/8_Lamb.webp";
import MONKEY from "../../asset/image/webp/9_Monkey.webp";
import ROOSTER from "../../asset/image/webp/10_Rooster.webp";
import DOG from "../../asset/image/webp/11_Dog.webp";
import PIG from "../../asset/image/webp/12_Pig.webp";
import WATER from "../../asset/image/elemental/Water.png";
import FIRE from "../../asset/image/elemental/Fire.png";
import EARTH from "../../asset/image/elemental/Earth.png";
import METAL from "../../asset/image/elemental/Metal.png";
import WOOD from "../../asset/image/elemental/Woods.png";
import NONE from "../../asset/image/elemental/None.png";

export const Elements = [
  { id: 1, src: `${NONE}`, name: "None" },
  { id: 2, src: `${EARTH}`, name: "Earth" },
  { id: 3, src: `${WOOD}`, name: "Wood" },
  { id: 4, src: `${METAL}`, name: "Metal" },
  { id: 5, src: `${WATER}`, name: "Water" },
  { id: 6, src: `${FIRE}`, name: "Fire" },
];

export const NFTSrc = [
  {
    id: 1,
    title: "Rat",
    image: `${RAT}`,
  },
  {
    id: 2,
    title: "Ox",
    image: `${OX}`,
  },
  {
    id: 3,
    title: "Tiger",
    image: `${TIGER}`,
  },
  {
    id: 4,
    title: "Rabbit",
    image: `${RABBIT}`,
  },
  {
    id: 5,
    title: "Dragon",
    image: `${DRAGON}`,
  },
  {
    id: 6,
    title: "Snake",
    image: `${SNAKE}`,
  },
  {
    id: 7,
    title: "Horse",
    image: `${HORSE}`,
  },
  {
    id: 8,
    title: "Goat",
    image: `${GOAT}`,
  },
  {
    id: 9,
    title: "Monkey",
    image: `${MONKEY}`,
  },
  {
    id: 10,
    title: "Rooster",
    image: `${ROOSTER}`,
  },
  {
    id: 11,
    title: "Dog",
    image: `${DOG}`,
  },
  { id: 12, title: "Pig", image: `${PIG}` },
];
