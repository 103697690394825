import React from 'react'
import { ApproveStaking } from '../../../components/buttons/staking/approve';
import { Staking } from '../../../components/buttons/staking/staking';

export const StakeItemsApproveStake = ({selectPlan, needApprove, setNeedApprove, stakesAmount, allowance, id, min, max, refetchData}) => {
  
// console.log(allowance, "stakeitemapprovestake", selectPlan, id)
  
  return (
    <div className="buttonApproveStake flex flex-col w-full">
                {needApprove ? (<ApproveStaking stakesAmount={stakesAmount} setNeedApprove={setNeedApprove}/>): (<Staking stakesAmount={stakesAmount} selectPlan={selectPlan} allowance={allowance} id={id} min={min} max={max} refetchData={refetchData}/>)}
                
              </div>
  )
}
