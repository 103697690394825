import React, { useState, useEffect } from "react";
import "./summonInfo.css";
import "./summoningCircle.css";
import { REACT_APP_API_ENDPOINT } from "../../../config.js";
import { NFTSrc, Elements } from "../../../components/utility/mapZodiac";
import axios from "axios";
import { formatUnits } from "viem";
import { SummonRoundTable } from "./summonRoundTable";
import SummonZodiac from "./summonZodiac";
import SummonNFT from "./summonNFT";
import SummonElements from "./summonElements";


export const SummoningCircle = ({account}) => {
  const [defaultChar, setDefaultChar] = useState(1);
  const [defaultEl, setDefaultEl] = useState(1);
  const [charOpacity] = useState(1);
  const [elementOpacity] = useState(1);
  const [char, setChar] = useState("Rat");
  const [elm, setElm] = useState("None");
  const [products, setProducts] = useState({});

  const handleNextChar = () => {
    setDefaultChar((prevId) => (prevId < NFTSrc.length ? prevId + 1 : 1));
  };

  const handlePrevChar = () => {
    setDefaultChar((prevId) => (prevId > 1 ? prevId - 1 : NFTSrc.length));
  };

  const handleNextElement = () => {
    setDefaultEl((prevId) => (prevId < Elements.length ? prevId + 1 : 1));
  };

  const handlePrevElement = () => {
    setDefaultEl((prevId) => (prevId > 1 ? prevId - 1 : Elements.length));
  };

  useEffect(() => {
    const charInfo = NFTSrc[defaultChar - 1];
    const elementInfo = Elements[defaultEl - 1];
    setChar(charInfo?.title);
    setElm(elementInfo?.name);
  }, [defaultChar, defaultEl]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const result = await axios.get(
          `${REACT_APP_API_ENDPOINT}/get-products?char=${char}&elm=${elm}`,
          
        );
        if (result && result.data.data.length > 0) {
          const item = result.data.data[0];
          setProducts({
            id: item.id,
            character: item.character,
            element: item.element,
            price: item.price !== undefined ? formatUnits(item.price, 18) : "0",
            title: item.title,
            image: item.image,
            cdn: item.cdn,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchProducts();
}, [char, elm, setProducts]);


  return (
    <div className="section h-screen w-full flex flex-col justify-around items-center m-auto relative bg-blue-800/20">
      <SummonRoundTable />

      <div className="char-select 2xl:w-[500px] xl:w-[500px] lg:w-[400px] w-screen max-h-screen m-auto flex flex-col justify-center items-center fixed top-0 z-20 ">
        <div className="top w-full 2xl:my-20 xl:my-20 lg:my-20 my-[70px]">
        <SummonElements
          defaultEl={defaultEl}
          handlePrevElement={handlePrevElement}
          handleNextElement={handleNextElement}
          elementOpacity={elementOpacity}
        />
          <SummonZodiac
            defaultChar={defaultChar}
            handlePrevChar={handlePrevChar}
            handleNextChar={handleNextChar}
            charOpacity={charOpacity}
          />
          <SummonNFT
            products={products}
            defaultChar={defaultChar}
            defaultEl={defaultEl}
            account={account}
          />
        </div>
        
        
      </div>

      <div className="salt-burst 2xl:top-[500px] xl:top-[500px] lg:top-[450px] top-[325px]"></div>
    </div>
  );
};
