import React from 'react'
import { Animator } from "@arwes/react-animator";
import { GridLines, Dots, MovingLines } from "@arwes/react-bgs";

export const AnimatedBackground = () => {
  return (
    <Animator duration={{ interval: 10 }}>
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "transparent",
        backgroundImage:
          "radial-gradient(85% 85% at 50% 50%, hsla(185, 100%, 25%, 0.5) 0%, hsla(185, 100%, 25%, 0.3) 50%, hsla(185, 100%, 25%, 0) 100%)",
      }}
    >
      <GridLines lineColor="hsla(180, 100%, 75%, 0.06)" distance={30} />
      <Dots color="hsla(180, 100%, 75%, 0.1)" distance={30} />
      <MovingLines
        lineColor="hsla(180, 100%, 75%, 0.375)"
        distance={30}
        sets={20}
      />
    </div>
  </Animator>

  )
}
