import React from "react";
import { useAccount, useDisconnect, useNetwork } from "wagmi";
import { switchNetwork } from '@wagmi/core'
import { FrameHexagon } from "../arwes/frames/frameHexagon";
import { Shorter } from "../utility/Shorter";
import OctagonButton from "../arwes/Buttons/OctagonButton";
import "./modal.css";

export function Profile({ show, onClose }) {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();

  const handleClosing = (e) => {
    if (e.target.id === "connected") onClose();
  };
 
  const handleSwitchNetwork = () => {
    try {
      switchNetwork?.({
        chainId: 56,


      })
    } catch (error) {
      console.log(error)
    }
  }


  if (!show) return null;

  const modalClassName = `containerModal fixed top-[80px] right-[14px] bg-black/30 backdrop-blur-sm flex justify-center items-center p-1 pointer-events-auto ${
    show ? "slide-in" : "slide-out"
  }`;
  const account = Shorter(address);

  return (
    <div id="connected" onClick={handleClosing} className={modalClassName}>
      <div className="wrapperModal flex flex-col justify-center items-center relative p-4">
        <FrameHexagon />
        <div className="buttonConnect relative w-[240px] px-1 py-5 flex flex-col z-50">
          <p className="text-end">Account Address:</p>
          <OctagonButton className="transition duration-500 hover:scale-75">
            <p className="relative">
              {account}
            </p>
          </OctagonButton>
          <p className="text-end">Connect to :</p>
          <OctagonButton>
            {isConnected && chain.id === 56 ? (
              <p className="relative">
                {chain.name}
              </p>
            ) : (
              <button onClick={handleSwitchNetwork} className="flex flex-col active:text-black focus:text-blue-800">
                <p className="relative">
                  Wrong Network! 
                </p>
                <p>{chain.name}</p>
              </button>
            )}
          </OctagonButton>
          <OctagonButton
            onClick={() => {
              disconnect();
              onClose();
            }}
          >
            <p className="p-2 relative hover:text-sky-900 focus:text-sky-800 active:text-sky-700">
              Disconnect
            </p>
          </OctagonButton>
        </div>
        <OctagonButton
          onClick={() => {
            onClose();
          }}
          className="relative hover:text-sky-900 focus:text-sky-800 active:text-sky-700 pointer-events-auto "
        >
          <p className="px-8 py-1"> Close </p>
        </OctagonButton>
      </div>
    </div>
  );
}
