import React, { useState } from "react";
import logoDesktop from "../../asset/image/twelve-logo.png";
import "./navigation.css";
import "../utility/neon.css";
import useViewportSize from "../hooks/useViewportSize";
import NavInfo from "./navInfo";
import MobileCheck from "../utility/MobileCheck";
import { ConnectorsProfile } from "../modal/modalConnect";
import { Profile } from "../modal/modalConnected";
import NavMenuSlider from "./navMenuSlider";
import { ConnectMobile } from "../buttons/navigations/buttonConnectMobile";
import { ConnectedMobile } from "../buttons/navigations/buttonConnectedMobile";
import { ConnectDesktop } from "../buttons/navigations/buttonConnectDesktop";
import { ConnectedDesktop } from "../buttons/navigations/buttonConnectedDesktop";
import { HamburgerMobile } from "../buttons/navigations/buttonHamburgerMobile";
import { useAccount } from "wagmi";
import { useLocation } from "react-router-dom";
import { MarketBalanceDesktop, StakingBalanceDesktop } from "./desktopBalance";
import { MarketBalanceMobile, StakingBalanceMobile } from "./mobileBalance";
// import Snowfall from 'react-snowfall';

const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [connect, setConnect] = useState(false);
  const { width, height } = useViewportSize();
  const { isConnected } = useAccount();

  const location = useLocation();
  const { pathname } = location;
  const isMarketplace = pathname === "/marketplace";
  const isStaking = pathname === "/earning";

  const topButton = (
    <MobileCheck
      onMobile={
        <>
          <div className="burger flex items-center ">
            {navOpen ? (
              <></>
            ) : (
              <>
                {isConnected && isMarketplace && <MarketBalanceMobile />}
                {isConnected && isStaking && <StakingBalanceMobile />}
                {isConnected ? (
                  <ConnectedMobile onClick={() => setConnect(true)} />
                ) : (
                  <ConnectMobile onClick={() => setConnecting(true)} />
                )}
              </>
            )}
            <HamburgerMobile navOpen={navOpen} setNavOpen={setNavOpen} />
          </div>
        </>
      }
      onDesktop={
        <>
          {isConnected ? (
            <ConnectedDesktop onClick={() => setConnect(true)} />
          ) : (
            <ConnectDesktop onClick={() => setConnecting(true)} />
          )}
          {isConnected && isMarketplace && <MarketBalanceDesktop />}
          {isConnected && isStaking && <StakingBalanceDesktop />}
        </>
      }
      mobileViewport={768}
    />
  );

  const closeModalConnectors = () => {
    setConnecting(false);
  };
  const closeModalProfile = () => {
    setConnect(false);
  };

  return (
    <>
      <div
        className="navigations fixed z-[11] flex items-center justify-center bg-transparent pointer-events-none"
        style={{
          height: `calc(${height}px)`,
          width: `calc(${width}px)`,
        }}
      >
        <div
          className="absolute flex flex-col border-2 border-blue12 rounded-r-3xl"
          style={{
            height: `calc(${height}px - 24px)`,
            width: `calc(${width}px - 24px)`,
          }}
        >
          {/* <Snowfall color="#fff" snowflakeCount={150} speed={[1.0, 3.0]}/> */}
          <div className="flex h-[50px] justify-between items-center transition pointer-events-auto w-full ">
            {navOpen && <NavInfo navOpen={navOpen} setNavOpen={setNavOpen} />}
            <div className="logo flex items-center top-0 left-0  px-3 pb-2">
              <a href="/">
                <img
                  src={logoDesktop}
                  alt="Desktop Logo"
                  className="2xl:h-[24px] xl:h-[24px] lg:h-[24px] h-[14px] mt-2"
                />
              </a>
            </div>
            <div className="start flex justify-end items-center w-auto right-0 top-0 ">
              {topButton}
              <Profile show={connect} onClose={closeModalProfile} />
            </div>

            
          </div>
          <div className="flex w-5 relative top-[50%] -translate-y-1/2">
          <div
              className={
                "start bg-black" +
                (navOpen
                  ? "transition duration-300 -translate-x-[15px] "
                  : "transition duration-300 -translate-x-[285px] backdrop-blur-sm")
              }
            >
              <NavMenuSlider navOpen={navOpen} setNavOpen={setNavOpen} />
            </div>
            </div>
          <ConnectorsProfile show={connecting} onClose={closeModalConnectors} />
        </div>
      </div>
    </>
  );
};

export default Navigation;
