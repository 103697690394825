import React from "react";
import axios from "axios";
import { REACT_APP_API_ENDPOINT } from "../../config";
import { Elements } from "../utility/mapZodiac";

export const useGetNFTStakeByAddress = ({ account }) => {
  const [error, setError] = React.useState();
  const [items, setItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  
  const getOwnedNFT = React.useCallback(async() => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        REACT_APP_API_ENDPOINT + "/getNftStakeByAddress?address=" + account
      );
      const data = response.data?.data;
      
      if (!data) {
        return;
      }

      const itemsData = data.map((item) => {
        const parts = item.title.split("(");
        const title = parts[1].replace(")", "").trim();
        const element = title.split(" ")[1];
        const elementIMG = Elements.find((el) =>
          el.src.toLowerCase().includes(element.toLowerCase())
        );

        return {
          title,
          fuel: item.fuel,
          tokenid: item.tokenid,
          price: item.price,
          cdn: item.cdn,
          file: item.file,
          max: item.maximum_stake,
          element: element,
          elementIMG: elementIMG?.src,
          amount: item.staking.amount,
          claimedReward: item.staking.claimed_reward,
          startBlock: item.staking.start_block,
          endBlock: item.staking.end_block,
          planId: item.staking.plan_id
          
        };
      });

      // Wait for all promises (API requests) to complete before updating state
      const resolvedItemsData = await Promise.all(itemsData);
      setItems(resolvedItemsData);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      setError(error.message || "An error occurred while fetching data.");
      setIsLoading(false); // Set isLoading to false even if there's an error
    }
  }, [account]);

  React.useEffect(() => {
    getOwnedNFT();
  }, [getOwnedNFT]);

    // Create the refetchData function
    const refetchData = React.useCallback(() => {
      getOwnedNFT();
      
    }, [getOwnedNFT]);

  return { items, error, isLoading, refetchData };
};
