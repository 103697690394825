import React from "react";
import { BtnIco } from "../buttons/navigations/menuButtons";
import CMC from "../../asset/image/Logo/CMC.png";
import {
  FaMedium,
  FaTelegramPlane,
  FaTwitter,
  FaInstagramSquare,
  FaYoutube,
} from "react-icons/fa";
const NavSocialMenu = () => {
  return (
    <div className="social-wrapper w-full  text-white px-5 py-2 flex justify-between items-center neon-text">
      <BtnIco
        as="a"
        href="https://t.me/twelvezodiacofficial"
        title="Telegram - Twelve Zodiac Official"
      >
        <FaTelegramPlane />
      </BtnIco>
      <BtnIco
        as="a"
        href="https://t.me/twelvezodiac"
        title="Telegram - Twelve Zodiac"
      >
        <FaTelegramPlane />
      </BtnIco>
      <BtnIco
        as="a"
        href="https://twitter.com/TwelveZodiac12"
        title="Twitter - Twelve Zodiac"
      >
        <FaTwitter />
      </BtnIco>
      <BtnIco
        as="a"
        href="https://www.instagram.com/twelvezodiac12"
        title="Instagram - Twelve Zodiac"
      >
        <FaInstagramSquare />
      </BtnIco>
      <BtnIco
        as="a"
        href="https://medium.com/@info_35082"
        title="Medium - Twelve Zodiac"
      >
        <FaMedium />
      </BtnIco>
      <BtnIco
        as="a"
        href="https://youtube.com/@TwelveZodiac"
        title="YouTube - Twelve Zodiac"
      >
        <FaYoutube />
      </BtnIco>
      <BtnIco
        as="a"
        href="https://coinmarketcap.com/currencies/twelve-zodiac/"
        title="CoinMarketCap - Twelve Zodiac"
      >
        <img
          src={CMC}
          alt=""
          className=" 2xl:h-[26px] xl:h-[26px] lg:h-[26px] h-[20px]"
        />
      </BtnIco>
    </div>
  );
};

export default NavSocialMenu;
