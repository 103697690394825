import React, { useState, useEffect, useRef, useCallback } from 'react';

const LazyLoad = ({ src, alt, ...other }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [currentSrc, setCurrentSrc] = useState(null);
  const containerRef = useRef(null);

  const cleanupFunction = useCallback(() => {
    if (containerRef.current) {
      const currentContainerRef = containerRef.current;

      // Cleanup code
      const observer = containerRef.current?.lazyImageObserver;
      if (observer) {
        observer.unobserve(currentContainerRef);
      }
    }
  }, []);

  useEffect(() => {
    let observer;
    let didCancel = false;

    const handleImageLoad = () => {
      if (!didCancel) {
        setImageLoaded(true);
      }
    };

    if (IntersectionObserver) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
              setCurrentSrc(src);
              observer.unobserve(entry.target);
            }
          });
        },
        {
          threshold: 0.01,
          rootMargin: '75%',
        }
      );

      if (containerRef.current) {
        observer.observe(containerRef.current);
        containerRef.current.lazyImageObserver = observer; // Store the observer instance
      }
    } else {
      setCurrentSrc(src);
    }

    const image = new Image();
    image.onload = handleImageLoad;
    image.src = src;

    return cleanupFunction;
  }, [src, cleanupFunction]);

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      {currentSrc && (
        <img
          src={currentSrc}
          alt={alt}
          style={{
            filter: imageLoaded ? 'none' : 'blur(60px)',
            opacity: imageLoaded ? 1 : 0,
            transition: 'opacity 0.8s',
          }}
          {...other}
        />
      )}
    </div>
  );
};

export default LazyLoad;
