/* global BigInt */
import React, { useState } from "react";
import { FrameHexagon } from "../arwes/frames/frameHexagon";
import OctagonButton from "../arwes/Buttons/OctagonButton";
import { AddTokenStaking } from "../buttons/staking/addToken";
import "./modal.css";
import { formatUnits } from "viem";
import { useFetchBalance } from "../hooks/useFetchBalance";


export function AddToken({ show, onClose, id, max, min, allowance, refetchData }) {
  const [needApproveAdd, setNeedApproveAdd] = useState(true);
  const [addStakesAmount, setAddStakesAmount] = useState(0);
  const [amountAdd, setAmountAdd] = useState(0);
  const { token } = useFetchBalance();
  
  const handleClosing = (e) => {
    if (e.target.id === "addTokens") onClose();
  };
  const changeAmountAdd = (e) => {
    const inputValue = e.target.value;
    const numericValue = parseFloat(inputValue); // Parse as a regular number

    if (!isNaN(numericValue)) {
      setAmountAdd(inputValue); // Update the input value in the state
      const reqAmount = BigInt(numericValue * 10 ** 18); // Convert to BigInt
      setAddStakesAmount(reqAmount);
      setNeedApproveAdd(allowance < reqAmount); // Simplified the approval check
    } else {
      setAmountAdd(inputValue); // Set the input value in the state, even if it's invalid
      setNeedApproveAdd(true);
    }
  };

  const handleMax = () => {
    if (token.formatted > max) {
      setAmountAdd(max);
    } else if (token.formatted < max && token.formatted > min) {
      setAmountAdd(token.formatted);
    }
    
    const amountFormat = BigInt(amountAdd * 10 ** 18); // Convert max to BigInt
    setAddStakesAmount(amountFormat);
    setNeedApproveAdd(allowance < amountFormat);
  }

  if (!show) return null;

  const modalClassName = `containerModal top-0 left-0 absolute z-[500] w-full h-full bg-black/30 backdrop-blur-sm flex justify-center items-center p-1 pointer-events-auto ${
    show ? "slide-in" : "fade-out"
  }`;

  return (
    <div id="addTokens" onClick={handleClosing} className={modalClassName}>
      <div className="wrapperModal w-full flex flex-col justify-center items-center relative p-4">
        <FrameHexagon />
        <div className="modalAddStake relative w-full  px-1 py-5 flex flex-col z-50">
          <p className="text-center font-semibold text-lg">Add</p>
          <p className="p-2">
            <strong className="animate-pulse text-lg">Attention!</strong> Adding
            Twelve to stake will reset the count, any rewards before adding the
            stake will be counted and sent automatically to your wallet
          </p>
          <p>Allowed Twelve : {allowance !== undefined ? (formatUnits(allowance,18)): 0}</p>
          <p className="text-white text-start py-2 text-lg">
            Input Amount to Add 
          </p>
          <div className="form-input flex items-center justify-between gap-3">
          <div className="forms py-4 px-3 w-[70%] rounded-xl bg-sky-400 flex justify-between items-center">
        <input
          type="number"
          className="w-full px-2 bg-transparent text-white 2xl:text-2xl xl:text-2xl lg:text-2xl md:text-2xl text-xl font-black border-none outline-none"
          value={amountAdd}
          min={min}
          max={max}
          onChange={changeAmountAdd}
          placeholder="0"
        />
        
        <button onClick={handleMax} className="px-4 py-1 rounded-lg bg-blue-600/70 font-semiibold">MAX</button>
        </div>
            <strong>$TWELVE</strong>
          </div>
          <p className="text-xs font-roboto text-start py-1">
            Min : {min} TWELVE Max: {max} TWELVE
          </p>
          <div className="theButton grid grid-cols-1">
          <AddTokenStaking id={id} refetchData={refetchData} addStakesAmount={addStakesAmount} needApproveAdd={needApproveAdd} setNeedApproveAdd={setNeedApproveAdd} allowance={allowance}/>
          </div>
        </div>
        <OctagonButton
          onClick={() => {
            onClose();
          }}
          className="relative group pointer-events-auto "
        >
          <p className="px-8 py-1 relative text-white/50 transition-all duration-300 group-hover:text-white"> 
            Cancel
          </p>
        </OctagonButton>
        
      </div>
    </div>
  );
}
