import React from "react";

export const MenuButton = ({
  type = "button",
  onClick,
  as: Component = "button",
  children,
  ...rest
}) => {
  return (
    <Component
      {...rest}
      onClick={onClick}
      className="outer w-[225px]   flex flex-col justify-center items-center"
    >
      <div
        className="outer-top bg-sky-600/70 w-[225px] -translate-y-2 text-transparent"
        style={{
          clipPath:
            "polygon(0 88%, 0 50%, 50% 49%, 58% 80%, 100% 80%, 100% 92%, 53% 92%, 43% 67%, 4% 67%, 4% 88%)",
        }}
      >
        .
      </div>
      <div
        className="inner w-[200px] h-[33px] flex items-center justify-start hover:bg-sky-400 "
        style={{
          clipPath:
            "polygon(0 0, 43% 0, 51% 10%, 100% 10%, 100% 81%, 65% 81%, 51% 100%, 0 100%)",
        }}
      >
        <p className=" font-menu 2xl:text-2xl xl:text-xl lg:text-xl text-lg">
          {" "}
          {children}{" "}
        </p>
      </div>

      <div
        className="outer-bottom bg-sky-700/40 w-[225px] -translate-y-2 text-transparent"
        style={{
          clipPath:
            "polygon(0 83%, 51% 83%, 66% 31%, 100% 33%, 100% 46%, 78% 46%, 64% 100%, 0 100%)",
        }}
      >
        .
      </div>
    </Component>
  );
};

export function BtnIco({
  type = "button",
  onClick,
  onMouseEnter,
  onMouseLeave,
  as: Component = "button",
  children,
  ...rest
}) {
  return (
    <>
      <Component
        {...rest}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className=" text-white/70 2xl:text-2xl xl:text-2xl lg:text-2xl text-xl flex bg-transparent hover:bg-sky-300 hover:rounded-full p-1 hover:text-white hover:shadow-white hover:drop-shadow-[0_1px_10px_rgb(51,255,255)] hover:duration-500 hover:transition-all"
      >
        {children}
      </Component>
    </>
  );
}

export function BtnAirdrop({
  type = "button",
  onClick,
  onMouseEnter,
  onMouseLeave,
  as: Component = "button",
  children,
  ...rest
}) {
  return (
    <>
      <Component {...rest} onClick={onClick}>
        {children}
      </Component>
    </>
  );
}
