import { useCallback, useEffect, useState } from "react";
import { NFTADDR, TOKENABI, TOKENADDR, USDTBEP20, USDTBEP20ABI } from "../../config";
import { readContract } from '@wagmi/core';


export const useAllowanceNFT = ({ account }) => {
  const [allowance, setAllowance] = useState(undefined);

  const fetchData = useCallback(async () => {
    const result = await readContract({
      address: USDTBEP20,
      abi: USDTBEP20ABI,
      functionName: "allowance",
      args: [account, NFTADDR],
    });

    // const fetchData = useCallback(async () => {
    //   const result = await readContract({
    //     address: TOKENADDR,
    //     abi: TOKENABI,
    //     functionName: "allowance",
    //     args: [account, NFTADDR],
    //   });

    setAllowance(result); // Update the allowance state with fetched data
  }, [account]);

  useEffect(()=>{
    fetchData()
  },[fetchData])

  const refetchData = useCallback(() => {
    fetchData();
  }, [fetchData]);

  return {
    allowance: allowance,
    refetch: refetchData,
  };
};
