import React from "react";
import { FaLink } from "react-icons/fa";

export const ConnectedMobile = ({onClick}) => {

  return (
   
      <button onClick={onClick} className="border-2 border-t-0 border-r-0 border-blue12 h-[50px] px-3 text-2xl text-blue12 button_slide slide_right">
        <FaLink />  
      </button>
      
    
  );
};
