import { useEffect, useState } from "react";
import { fetchBalance, getWalletClient } from "@wagmi/core";
import { TOKENADDR, USDTBEP20 } from "../../config";
import Swal from "sweetalert2";

export function useFetchBalance () {
  const [balances, setBalances] = useState({
    bnb: 0,
    token: 0,
    usdt: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initiateBalance = async () => {
      const walletClient = await getWalletClient();

      if (
        !walletClient ||
        !walletClient.account ||
        !walletClient.account.address
      ) {
        // Retry after a short delay
        setTimeout(initiateBalance, 1000);
        return;
      }

      const account = walletClient.account.address;

      try {
        const coin = await fetchBalance({ address: account });
        const token = await fetchBalance({
          address: account,
          token: TOKENADDR
        });
        const tether = await fetchBalance({
          address: account,
          token: USDTBEP20
        });

        setBalances({
          bnb: coin,
          token: token,
          usdt: tether,
        });
      } catch (error) {
        setBalances({ bnb: 0, usdt: 0, token: 0 });
        console.error(error);
        if(error.shortMessage) {
          Swal.fire({
          icon: "error",
          title: "Oops",
          html: `${error.shortMessage}`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "There's something's wrong",
          html: `Contact support@twelvezodiac.co`,
        });
      }
      setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading) {
      initiateBalance();
    }
  }, [setBalances, isLoading]);

  return { ...balances };
};


