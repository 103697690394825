/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef } from 'react';
import { FrameSVGCorners, useFrameSVGAssemblingAnimation } from '@arwes/react-frames';

export const FrameCorners = () => {
  const svgRef = useRef(null);
  const { onRender } = useFrameSVGAssemblingAnimation(svgRef);

  return (
    <div >
      <FrameSVGCorners
        elementRef={svgRef}
        onRender={onRender}
        css={css`
     

      [data-name="bg"] {
        color: rgba(3, 121, 172, 0.59)
      },
      [data-name="line"] {
        color: rgba(0, 240, 253, 0.8)
      }`
    }
      />
    </div>
  );
};
