import React, { useState } from "react";
import Swal from "sweetalert2";
import { parseUnits } from "viem";
import { NFTADDR, TOKENABI, TOKENADDR, USDTBEP20ABI, USDTBEP20 } from "../../../config";
import { prepareWriteContract, writeContract, waitForTransaction } from "@wagmi/core";


export function ApproveBuy({ amount, setIsLoading }) {
  const [error, setError] = useState();
  const [isProgress, setIsProgress] = useState(false);
  // const [btnDIsabled, setBtnDisabled] = useState(true);

  const handleApproveBuy = async () => {
    const formatAmount = parseUnits((amount.toString()),18)
    if (isProgress) return;
      Swal.fire({
        title: "Are you sure?",
        html:` You are going to use ${amount} USDT`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, approve it!",
        cancelButtonText: "No, cancel!",
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        reverseButtons: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          // User clicked "Approve"
          setIsProgress(true);
          try {
              const { request } = await prepareWriteContract({
                address: USDTBEP20,
                abi: USDTBEP20ABI,
                functionName: "approve",
                args: [NFTADDR, formatAmount],
              });
              Swal.fire({
                title: "Please Wait",
                html: "Connecting to <strong classNames='text-blue-900'>Network...</strong>",
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
              const { hash } = await writeContract(request);
              Swal.fire({
                title: "Please Wait",
                html: "Writing Transaction...",
                timerProgressBar: true,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
            
              const data = await waitForTransaction({ hash });
        
              if (data) {
                const bscScanLink = `https://bscscan.com/tx/${hash}`;
                Swal.fire({
                  icon: "success",
                  title: "Horray..",
                  html: `Transaction success! <a href=${bscScanLink} target="_blank" rel="noopener noreferrer"><u><b>View Here</b> </u></a>`,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops",
                  text: "Transaction failed or was canceled",
                });
                setIsProgress(false);
              }
            } catch (err) {
              setError(err);
              console.log(error);
              if(error.details) {
                Swal.fire({
                icon: "error",
                title: "Transaction Cancelled",
                html: `${error.details}`,
              });
            } else if(error.shortMessage) {
              Swal.fire({
                icon: "error",
                title: "Transaction Cancelled",
                html: `${error.shortMessage}`,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error on Approve to Buy Function",
                html: `Contact support@twelvezodiac.co `,
              });
            }
              setIsProgress(false);
            } finally {
              setIsProgress(false);
              setIsLoading(true);
            }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // User clicked "Cancel"
          Swal.fire(
            "Cancelled",
            "Transaction Cancelled",
            "error"
          );
          setIsProgress(false);
        }
      });
  };
   
  return (
    <>
      <button
        disabled={isProgress}
        onClick={handleApproveBuy}
        className="summoner relative 2xl:w-[200px] xl:w-[200px] lg:w-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] w-[150px] h-[150px] flex justify-center items-center"
      >
        <p className="text-3xl text-white z-[5]">Approve</p>
      </button>
    </>
  );
}
