import { Routes, Route, Navigate } from "react-router-dom";
import Start from "../pages/startPage";
import Staking from "../pages/stakingPage";
import Collection from "../pages/collectionPage";
import Marketplace from "../pages/marketplacePage";


export default function Routers({account}) {
  // Assuming you have a state to keep track of the login status
  const isLoggedIn = !!account; // Check if the account exists

  return (
    <>
      <Routes>
          {/* Public routes accessible to everyone */}
        <Route exact path="/" element={<Start />} />
        

        {/* Private routes accessible only to logged-in users */}
        {isLoggedIn ? (
          <>
            <Route exact path="/collection" element={<Collection />} />
            <Route exact path="/marketplace" element={<Marketplace account={account}/>} />
            <Route exact path="/earning" element={<Staking account={account} />} />
          </>
        ) : (
          // Redirect to the dashboard for non-logged-in users trying to access private routes
          <Route path="*" element={<Navigate to="/" />} />
        )}
      </Routes>
    </>
  );
}


