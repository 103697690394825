import React from "react";
import { FaWallet } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { FrameNox } from "../arwes/frames/frameNox";
import { Sling as Hamburger } from "hamburger-react";
import "../utility/neon.css"

const NavMenuSlider = ({ navOpen, setNavOpen }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="menu-wrapper z-[50] relative 2xl:flex xl:flex lg:flex hidden flex-col items-center justify-center p-3 pointer-events-auto">
        <FrameNox />
        <div className="menu-inside relative flex justify-center items-center h-[360px] w-[300px]">
          <div className="menu-button px-6 w-[80%] font-['Play'] flex flex-col items-start justify-center gap-5">
          <Link to="https://twelvezodiac.co">
          <button
              className="py-3 flex justify-start items-center gap-3 text-lg text-blue12 hover:text-yello transition-all duration-300"
            >
              <FaWallet /> Home
            </button>
            </Link>
            <button
              onClick={() => {
                navigate("/");
              }}
              className="py-3 flex justify-start items-center gap-3 text-lg text-blue12 hover:text-yello transition-all duration-300"
            >
              <FaWallet /> Dashboard
            </button>
            <button
              onClick={() => {
                navigate("/marketplace");
              }}
              className="py-3 flex justify-start items-center gap-3 text-lg text-blue12 hover:text-yello transition-all duration-300"
            >
              <FaWallet /> Summon Zodiac
            </button>
            <button
              onClick={() => {
                navigate("/collection");
              }}
              className="py-3 flex justify-start items-center gap-3 text-lg text-blue12 hover:text-yello transition-all duration-300"
            >
              <FaWallet /> Zodiac
            </button>
            <button
              onClick={() => {
                navigate("/earning");
              }}
              className="py-3 flex justify-start items-center gap-3 text-lg text-blue12 hover:text-yello transition-all duration-300"
            >
              <FaWallet /> Earning
            </button>
          </div>
          <div className="menu-slider-container h-full w-[30px] flex justify-start relative ">
            <div className="menu-slider-wrapper flex items-center h-[100px] bottom-[28%] absolute">
              <Hamburger
                toggled={navOpen}
                toggle={setNavOpen}
                size={20}
                duration={0.3}
                easing="ease-in"
                color="#4FD1C5"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavMenuSlider;
