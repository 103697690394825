import { useEffect } from "react";
import { useConnect } from "wagmi";
import { FrameHexagon } from "../arwes/frames/frameHexagon";
import { FrameOctagon } from "../arwes/frames/frameOctagon";
import { Rounded as Preloader } from "../utility/preloader";
import "./modal.css";

export function ConnectorsProfile({ show, onClose }) {

  const { connect, connectors, error, isLoading, pendingConnector } = useConnect();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.id === "connect") {
        onClose();
      }
    };

    if (show) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [show, onClose]);

  const handleConnect = (connector) => {
    connect({ connector });
    onClose();
  };

  if (!show) return null;

  const modalClassName = `containerModal fixed inset-0 bg-black/30 backdrop-blur-sm flex justify-center items-center pointer-events-auto transition-all ${
    show ? "fade-in" : "fade-out"
  }`;

  if (isLoading) {
    return (
      <div id="connect" onClick={onClose} className={modalClassName}>
        <div className="wrapperModal flex justify-center items-center relative fade-in">
          <FrameHexagon />
          <div className="buttonConnect w-[280px] px-3 py-5 flex flex-col gap-5 z-50">
            <Preloader height={"24px"} width={"24px"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="connect" onClick={onClose} className={modalClassName}>
      <div className="wrapperModal flex justify-center items-center relative">
        <FrameHexagon />
        <div className="buttonConnect w-[280px] px-3 py-5 flex flex-col gap-5 z-50">
          {connectors.map((connector) => (
            <button
              disabled={!connector.ready}
              key={connector.id}
              onClick={() => handleConnect(connector)}
              className="p-3 flex justify-center items-center relative"
            >
              <FrameOctagon />
              <span className="text-[#4FD1C5] hover:text-white hover:font-semibold flex gap-10 z-[60]">
                {connector.name}
                {!connector.ready && " (unsupported)"}
                {isLoading && connector.id === pendingConnector?.id && (
                  <Preloader height={"24px"} width={"24px"} />
                )}
              </span>
            </button>
          ))}

          {error && <div>{error.message}</div>}
        </div>
      </div>
    </div>
  );
}
