import React, { useRef, useState } from "react";
import { REACT_APP_API_ENDPOINT } from "../../../config";
import "./stakeContent.css"; // Replace with your CSS file path
import { Blocks as Preloader } from "../../../components/utility/preloader";
import { StakingItems } from "./stakingItems";
import { useGetNFTStakeByAddress } from "../../../components/hooks/useGetNFTStakeByAddress";
import { useFetchPlans } from "../../../components/hooks/useFetchPlans";
import useFileLoadingProgress from "../../../components/hooks/useFileLoadingProgress";


 

const StakeContent = ({account}) => {
  const [fakeLoading, setFakeLoading] = useState(0);
  const carouselRef = useRef(null);

  const slideNext = () => {
    carouselRef.current.scrollLeft += carouselRef.current.offsetWidth;
  };

  const slidePrev = () => {
    carouselRef.current.scrollLeft -= carouselRef.current.offsetWidth;
  };

  const {plans} = useFetchPlans();
  const { items, isLoading, refetchData } = useGetNFTStakeByAddress({
    account: account,
  });


  const loadingProgress = useFileLoadingProgress(
    REACT_APP_API_ENDPOINT + "/getNftStakeByAddress?address=" + account
  );
    
   
  React.useEffect(() => {
    const incrementProgress = () => {
      setFakeLoading((prevProgress) => {
        const newProgress = prevProgress + 30;
        return newProgress > 100 ? 100 : newProgress;
      });
    };

    const interval = setInterval(incrementProgress, 300);
    return () => clearInterval(interval);
  }, []);

  if (isLoading || loadingProgress < 100) {
    // Show the preloader when data is loading
    return (
      <div className="stake-content 2xl:h-[80%] xl:h-[85%] lg:h-[90%] h-[76%] 2xl:w-[80%] xl:w-[80%] lg:w-[80%] w-[90%] 2xl:p-3 xl:p-3 lg:p-3 ">
        <div className="carousel h-full gap-1 flex flex-col justify-center items-center" ref={carouselRef}>
          <Preloader height={"200px"} width={"200px"} />
          <p className="animate-pulse text-3xl font-bold">Loading Data...({fakeLoading.toFixed(2)}%)</p>
        </div>
      </div>
    );
  }

  return (
    <div className="stake-content 2xl:h-[80%] xl:h-[80%] lg:h-[80%] h-[75%] 2xl:w-[80%] xl:w-[80%] lg:w-[80%] w-[90%] 2xl:p-3 xl:p-3 lg:p-3 2xl:border-none xl:border-none lg:border-none border border-[#4FD1C5]">
      <div className="carousel h-full gap-1" ref={carouselRef}>
        {items.map((item) => (
          <StakingItems key={item.tokenid} tokenid={item.tokenid} data={item} plans={plans} account={account} refetchData={refetchData} />
        ))}
      </div>
      <div className="carousel-controls flex justify-center items-center gap-10 my-3 ">
        <button className="prev-button p-4" onClick={slidePrev}>
          Prev
        </button>
        <button className="next-button p-4" onClick={slideNext}>
          Next
        </button>
      </div>
      
    </div>
  );
};

export default StakeContent;
