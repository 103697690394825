const MobileCheck = ({ onMobile, onDesktop, mobileViewport = 768 }) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isMobileViewport = window.innerWidth <= mobileViewport;
  
    return isMobile || isMobileViewport ? onMobile : onDesktop;
  };
  
  export default MobileCheck;
  
  // Example on how to use useMobile
  // const logo = (
  //   <MobileAwareComponent
  //     onMobile={
  //       <a href="/">
  //         <img src={logoMobile} alt="Mobile Logo" className="h-[60px] p-2" />
  //       </a>
  //     }
  //     onDesktop={
  //       <a href="/">
  //         <img src={logoDesktop} alt="Desktop Logo" className="h-[24px] " />
  //       </a>
  //     }
  //     mobileViewport={768}
  //   />
  // );