/* eslint-disable no-undef */
import React, { useState, useRef } from "react";
import NavMenu from "./navMenu";
import NavSocialMenu from "./navSocialMenu";
import { Animator } from "@arwes/react-animator";
import { Text } from "@arwes/react";
import { BtnNavInfo } from "../buttons/navigations/buttonNavInfo";
import "../utility/neon.css"

const NavInfo = ({navOpen, setNavOpen}) => {
  const [copied, setCopied] = useState(false);
  const addressInputRef = useRef(null);
  const address = "0xBD6ceeEf56985B608252C3651dd903a3fCc34910";

  const copyAddress = async () => {
    try {
      await navigator.clipboard.writeText(address);
      setCopied(true);

      setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
    } catch (error) {
      fallbackCopyToClipboard(); // Fallback method for unsupported browsers
    }
  };

  const fallbackCopyToClipboard = () => {
    const textarea = document.createElement("textarea");
    textarea.value = address;
    textarea.setAttribute("readonly", "");
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };

  

  return (
    <div className="absolute  rounded-r-3xl w-full top-0 bottom-0 left-0 transition-all duration-500 2xl:hidden xl:hidden lg:hidden pointer-events-auto">
      <div className="2xl:w-[5%] xl:w-[5%] lg:w-[5%] w-full h-full flex flex-col items-center justify-center  text-white bg-[#08142e]/80 rounded-r-3xl ">
       
        <div className=" flex flex-col my-5 w-full justify-center items-center">
          <NavMenu setNavOpen={setNavOpen} navOpen={navOpen}/>
        </div>
 
        <div className="info w-full">
          <NavSocialMenu />

          <div className="infos flex flex-col 2xl:h-[26vh] xl:h-[26vh] lg:h-[26vh] h-[26vh] justify-between">
            <Animator duration={{ enter: 1.5, exit: 1.5 }}>
              <div className="info text-start px-5 flex flex-col font-['Play']">
                <p className="font-black text-lg font-['Play'] drop-shadow-[0_1px_5px_rgb(255,255,255)]">Token Info</p>
                <div className="lines grid grid-cols-3">
                  <Text className="font-bold 2xl:text-base xl:text-base lg:text-base text-xs font-['Play']">
                    Name :
                  </Text>{" "}
                  <span className="font-black font-['Play'] drop-shadow-[0_1px_5px_rgb(255,255,255)]">$TWELVE</span>
                </div>
                <div className="lines grid grid-cols-3">
                  <Text className="font-bold 2xl:text-base xl:text-base lg:text-base text-xs font-['Play']">
                    Decimal :
                  </Text>{" "}
                  <span className="font-black font-['Play'] drop-shadow-[0_1px_5px_rgb(255,255,255)]">18</span>
                </div>
                <Text className="font-bold 2xl:text-base xl:text-base lg:text-base text-xs font-['Play']">
                  Address :
                </Text>

                <div
                  
                  className="font-black flex flex-wrap py-2 bg-sky-600/30 w-full whitespace-normal"
                >
                  <p className="font-['Play'] 2xl:text-lg xl:text-lg lg:text-lg text-xs drop-shadow-[0_1px_5px_rgb(255,255,255)]">
                    0x BD6c eeEf 5698 5B60 8252 C365 1dd9 03a3 fCc3 4910
                  </p>
                </div>
                <div className="addressButton w-full flex justify-between items-center mt-2">
                 
                  <BtnNavInfo copyAddress={copyAddress} address={address} copied={copied} />
                  {/* Hidden input field for fallback copy functionality */}
                  <textarea
                    ref={addressInputRef}
                    readOnly
                    value={address}
                    style={{ position: "absolute", left: "-9999px" }}
                  />
                </div>
              </div>
            </Animator>
            <div className="foot">
              <span className="text-white 2xl:text-lg xl:text-lg lg:text-lg text-xs   font-['Play']">
                {" "}
                Need Help? Please send email to : <b className="drop-shadow-[0_1px_5px_rgb(255,255,255)]">support@twelvezodiac.co</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavInfo;
