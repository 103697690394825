import React from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { useSwipeable } from "react-swipeable";
import { Elements } from "../../../components/utility/mapZodiac";
import LazyLoad from "../../../components/utility/LazyLoad";

const SummonElements = ({
  defaultEl,
  handlePrevElement,
  handleNextElement,
  elementOpacity,
}) => {
  const elementHandlers = useSwipeable({
    onSwipedLeft: handleNextElement,
    onSwipedRight: handlePrevElement,
  });

  return (
    <div className="elements flex flex-col justify-center items-center " {...elementHandlers}>
      <p className="2xl:text-xl xl:text-xl lg:text-xl text-base">Select Your Elements</p>
      <p className="text-center text-lg font-bold font-['Play']">
        {Elements[defaultEl - 1].name}
      </p>
      <div
        className="elm flex justify-around items-center "
      >
        <button onClick={handlePrevElement} className="arrow-button mx-3">
          <RiArrowLeftSLine className="arrow-icon text-4xl font-bold" />
        </button>
        <div
          
          className={`element-container 2xl:mt-3 xl:mt-3 lg:mt-3 2xl:h-[60px] xl:h-[60px] lg:h-[60px] h-[32px] 2xl:w-[60px] xl:w-[60px] lg:w-[60px] w-[32px] ${
            elementOpacity === 1 ? "opacity-100" : "opacity-0"
          } transition-opacity duration-300`}
        >
          <LazyLoad
            key={defaultEl}
            src={Elements[defaultEl - 1].src}
            alt=""
            className="2xl:h-[60px] xl:h-[60px] lg:h-[60px] h-[32px]"
          />
        </div>
        <button onClick={handleNextElement} className="arrow-button mx-3">
          <RiArrowRightSLine className="arrow-icon text-4xl font-bold" />
        </button>
      </div>
    </div>
  );
};

export default SummonElements;
