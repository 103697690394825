import React from 'react'
import { FaWallet } from "react-icons/fa";

export const ConnectMobile = ({onClick}) => {
  return (
    <button
    onClick={onClick}
    className="border-2 border-t-0 border-r-0 border-blue12 h-[50px] px-3 text-2xl text-[#4FD1C5] button_slide slide_right" 
  >
    <FaWallet />
  </button>
  )
}
