import React from "react";
import { IoReloadSharp } from "react-icons/io5";
export const StakeItemsFuel = ({fuel, refetchData}) => {
  return (
    <>
      <div className="content-mid relative flex flex-col justify-center items-center w-full 2xl:h-[50px] xl:h-[50px] lg:h-[50px] h-[20px] 2xl:mb-20 xl:mb-20 lg:mb-20 mb-16">
        <div className="circle1 2xl:h-[180px] xl:h-[180px] lg:h-[180px] h-[125px] 2xl:w-[180px] xl:w-[180px] lg:w-[180px] w-[125px] border-4 border-l-[#4FD1C5] border-t-[#4FD1C5] border-r-[#ec33fd] border-b-[#ec33fd] rounded-full bg-black/40 flex justify-center items-center absolute">
          <div className="circle2 2xl:h-[120px] xl:h-[120px] lg:h-[120px] h-[80px] 2xl:w-[120px] xl:w-[120px] lg:w-[120px] w-[80px] border-4 border-[#4FD1C5] border-l-0 border-t-0 rounded-full bg-black/20 flex flex-col justify-center items-center "></div>
        </div>
        <div className="content-fuel absolute ">
          <p className="text-lg font-bold font-play italic ">Fuel</p>
          <p className="2xl:text-4xl xl:text-4xl lg:text-4xl text-2xl font-bold font-play italic  bg-sky-700/10 backdrop-blur-sm px-5 py-2 rounded-xl text-[#4FD1C5]">
            {fuel !== undefined ? fuel : "o"}
          </p>
        </div>
        <div className="reload absolute right-0">
        <button className="text-xs font-semibold rounded-lg flex flex-col justify-center items-center bg-sky-700/60 border border-[#4FD1C5] p-2" onClick={() => refetchData()}> <span className="text-2xl"><IoReloadSharp/></span>  Reload</button>
        </div>
      </div>
      
    </>
  );
};
