import { NumericFormat } from "react-number-format";
import { useFetchBalance } from "../hooks/useFetchBalance";


export function MarketBalanceMobile () {
    const { token, usdt } = useFetchBalance();

    return (
    <div className="balances flex flex-col items-end  ">
    <div className="wrapper">
     <NumericFormat
        value={usdt?.formatted}
        displayType="text"
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={4}
        renderText={(value) => (
          <p className="font-semibold px-4 py-3 h-[50px] text-center text-xs border border-blue12 ">
            {value} {token?.symbol}
          </p>
        )}
      />
    </div>
   
  </div>
  )
}

export function StakingBalanceMobile () {
    const { token, bnb } = useFetchBalance();

    return (
    <div className="balances flex flex-col items-end px-4 py-2 h-[50px] border border-blue12">
    <div className="wrapper relative w-full">
     
      <NumericFormat
        value={token?.formatted}
        displayType="text"
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={4}
        renderText={(value) => (
          <p className="font-semibold text-end text-[10px] ">
            {value} {token?.symbol}
          </p>
        )}
      />
    </div>
    <div className="wrapper relative w-full">
     
      <NumericFormat
        value={bnb?.formatted}
        displayType="text"
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={4}
        renderText={(value) => (
          <p className="font-semibold text-end text-[10px]">
            {value} {bnb?.symbol}
          </p>
        )}
      />
    </div>

  </div>
  )
}