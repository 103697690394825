import React from 'react';
import { HexagonButton } from '../../arwes/Buttons/HexagonButton';
import "../../utility/neon.css"

export const PlanButton = ({ isSelected, title, onClick }) => {
  const bgColor = isSelected ? 'drop-shadow-[0_1px_5px_rgb(255,255,255)]' : '';
  const textColor = isSelected ? 'text-white font-bold drop-shadow-[0_1px_5px_rgb(255,255,255)]' : 'text-[#4FD1C5] font-semibold';

  return (
    <HexagonButton
      onClick={onClick}
      className={`bottom p-3 ${bgColor} hover:text-white focus:text-white focus:animate-pulse active:text-orange-400`}
    >
      <p className={`relative transition-all duration-300 ${textColor}`}>
        {title}
      </p>
    </HexagonButton>
  );
};


