import React, { useEffect, useRef } from 'react';
import './soundbar.css';

const Soundbar = () => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const bars = 10;
    const wrapper = wrapperRef.current;
    const objects = [];

    for (let i = 0; i < bars; i++) {
      const bar = document.createElement('div');
      bar.classList.add('bar');
      bar.style.left = `${10 * i}px`;
      bar.style.animationDuration = `${Math.random() * (600 - 300) + 300}ms`;
      wrapper.appendChild(bar);
      objects.push(bar);
    }

    return () => {
      objects.forEach((bar) => bar.remove());
    };
  }, []);

  return <div id="soundbar" ref={wrapperRef}></div>;
};

export default Soundbar;
