import React from 'react'
import './staking.css';
import  StakeContent  from './pageComponents/stakingPageComponents/stakeContent';


const FixedLayer = () => {
    return (
      <div className="fixed-layer-staking">
       
      </div>
    );
  };

const Staking = ({account}) => {
  return (
    <main className="Main">
      <FixedLayer />
 
      <div className="scrollable-layer-staking z-10">
      
      <div className="wrapper fixed z-20 w-full h-full 2xl:top-[80px] xl:top-[80px] lg:top-[80px] top-[75px]">
        <section className="section h-screen w-screen flex justify-center ">
         
         <StakeContent account={account}/>
        </section>
       
        </div>
      </div>
    </main>
    
  )
}

export default Staking;